import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import storeCreator from '../../../../js/app/react/storeCreator';
import { theme } from '../../../theme/theme';

interface Props {
  children: ReactNode;
}

export const AuthProviders = ({ children }: Props) => {
  const store = storeCreator(() => {});

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>{children}</Provider>
    </ThemeProvider>
  );
};
