import get from 'lodash/get';
import { createSelector } from 'reselect';
import {
  accountsGroupingType as adviserAccountsGroupingSelector,
  currentPage as advCurrentPage,
  hasCreditMonitoringSelector as advisorHasCreditMonitoringSelector,
  hasDataAggregationSelector as adviserHasDataAggregationSelector,
  hasDataReconciliationSelector as advisorHasDataReconciliationSelector,
  hasEStatementSettingsSelector as adviserHasEStatementSettingsSelector,
  hasPlaidSelector as advisorHasPlaidSelector,
  selectHasAkoya as adviserSelectHasAkoya,
  isDocumentVaultReadonlySelector as advisorIsDocumentVaultReadonlySelector,
  isDocumentVaultVisibleSelector,
  selectAssetBucketingHeadline as selectAdvisorAssetBucketingHeadline,
  selectFeatureFlags as selectAdvisorFeatureFlags,
  selectFooterDisclosures as selectAdvisorFooterDisclosures,
  selectHasAdvisorAssetBucketingLayoutAccess as advisorSelectHasAdvisorAssetBucketingLayoutAccess,
  selectHasBeFi20 as advisorSelectHasBeFi20,
  selectHasPulseCheck as advisorSelectHasPulseCheck,
  selectHasRightCapitalAccess as selectAdvisorHasRightCapitalAccess,
  selectOrgLogo as selectAdvisorOrgLogo,
  selectOrionWidgetsChartType as adviserSelectOrionWidgetsChartType,
  selectPortalName as selectAdvisorPortalName,
  selectRiskToleranceQuestionnaire as selectAdvisorRiskToleranceQuestionnaire,
  selectShowAlternativeDetail as advisorSelectShowAlternativeDetail,
  selectShowApexAcatsTransferFlow as adviserSelectShowApexAcatsTransferFlow,
  selectShowExecutiveSummary as adviserSelectShowExecutiveSummary,
  showEMoneyIntegrationSelector as adviserShowEMoneyIntegrationSelector,
  showFinancialPlanningSelector as adviserShowFinancialPlanningSelector,
  showManagedAccountsSelector as adviserShowManagedAccountsSelector,
  showMgpIntegrationSelector as adviserShowMgpIntegrationSelector,
  showOrionTradingSelector as adviserShowOrionTradingSelector,
  showPortfolioReportsSelector as adviserShowPortfolioReportsSelector,
  showRISAIntegrationSelector as adviserShowRISAIntegrationSelector,
  showRiskToleranceWorkflowSelector as advisorShowRiskToleranceWorkflowSelector,
  showSchwabIntegrationSelector as adviserShowSchwabIntegrationSelector,
  showTransactionsPageSelector as adviserShowTransactionsPageSelector,
  firmSettingsAccessSelector as adviserFirmSettingsAccessSelector,
} from '../AdviserArea/AdviserAreaSelector';
import { getViewport } from '../CommonComponents/withResponsiveness';

import {
  accountsGroupingType as consumerAccountsGroupingSelector,
  consumerAccessWorkflowSelector as consumerSelectOnboardingWorkflow,
  consumerOptOuts as consumerOptOutsSelector,
  currentPage as conCurrentPage,
  hasCreditMonitoringSelector as consumerHasCreditMonitoringSelector,
  hasDataReconciliationSelector as consumerHasDataReconciliationSelector,
  hasEStatementSettingsSelector as consumerHasEStatementSettingsSelector,
  hasPlaidSelector as consumerHasPlaidSelector,
  selectHasAkoya as consumerSelectHasAkoya,
  isDocumentVaultReadonlySelector as consumerIsDocumentVaultReadonlySelector,
  isFinancialPlanEnabledSelector as consumerIsFinancialPlanEnabledSelector,
  selectCanAccessPersonalFinances as consumerSelectCanAccessPersonalFinances,
  selectComponentName,
  selectComponentProps,
  selectFeatureFlags as selectConsumerFeatureFlags,
  selectFooterDisclosures as selectConsumerFooterDisclosures,
  selectHasBeFi20 as consumerSelectHasBeFi20,
  selectHasPulseCheck as consumerSelectHasPulseCheck,
  selectHasRightCapitalAccess as selectConsumerHasRightCapitalAccess,
  selectIsProfileEnable,
  selectLanguagePreference as selectConsumerLanguagePreference,
  selectOrgLogo as selectConsumerOrgLogo,
  selectOrionWidgetsChartType as consumerSelectOrionWidgetsChartType,
  selectPortalName as selectConsumerPortalName,
  selectRiskToleranceQuestionnaire as selectConsumerRiskToleranceQuestionnaire,
  selectShowAlternativeDetail as consumerSelectShowAlternativeDetail,
  selectShowApexAcatsTransferFlow as consumerSelectShowApexAcatsTransferFlow,
  selectShowExecutiveSummary as consumerSelectShowExecutiveSummary,
  selectShowRelatedHouseholds as consumerSelectShowRelatedHouseholds,
  showDocumentVaultSelector,
  showEMoneyIntegrationSelector as consumerShowEMoneyIntegrationSelector,
  showFinancialPlanningSelector as consumerShowFinancialPlanningSelector,
  showManagedAccountsSelector as consumerShowManagedAccountsSelector,
  showMgpIntegrationSelector as consumerShowMgpIntegrationSelector,
  showNetWorthSelector as consumerShowNetWorthSelector,
  showOrionTradingSelector as consumerShowOrionTradingSelector,
  showPortfolioReportsSelector as consumerShowPortfolioReportsSelector,
  showReportsSelector as consumerShowReportsSelector,
  showRISAIntegrationSelector as consumerShowRISAIntegrationSelector,
  showSchwabIntegrationSelector as consumerShowSchwabIntegrationSelector,
  showTransactionsPageSelector as consumerShowTransactionsPageSelector,
  showTransactionsSelector as consumerShowTransactionsSelector,
} from '../ConsumerArea/ConsumerAreaSelector';

import {
  selectHasDocumentVaultAccess,
  selectFeatureFlags as pdfSelectFeatureFlags,
} from '../PDFRenderer/PDFRendererSelector';

import {
  ChartType,
  CurrentClientDTO,
  ExternalAccountProviderAccessDTO,
  FeatureFlag,
  FooterDisclosures,
  LanguageCode,
  OnboardingWorkflow,
  RiskToleranceQuestionnaireType,
  ShowRelatedHouseholdsOption,
  UserRole,
} from './CommonArea.types';

const { ADV } = window;

export const isAdviserArea = state => !!state.AdviserArea;

export const isConsumerArea = state => !!state.ConsumerArea;

export const isPdfArea = state => state && !!state.pdfReducer;

export const currentPage = state => {
  if (ADV.util.env('isConsumerArea')) {
    return conCurrentPage(state);
  }

  return advCurrentPage(state);
};

export const currentClientIdSelector = state => {
  if (ADV.util.env('isAdviserArea')) {
    return get(state, 'AdviserArea.currentClient.Id');
  }

  if (ADV.util.env('isConsumerArea')) {
    return get(state, 'ConsumerArea.client.Id');
  }

  if (ADV.util.env('isPrintArea')) {
    return get(state, 'pdfReducer.client.Id');
  }

  return null;
};

/**
 * @deprecated Use "selectCurrentClient" instead.
 * thanks <3
 */
export const currentClientSelector = state => {
  if (ADV.util.env('isAdviserArea')) {
    return state.AdviserArea.currentClient;
  }

  if (ADV.util.env('isConsumerArea')) {
    return state.ConsumerArea.client;
  }

  if (ADV.util.env('isPrintArea')) {
    return ADV.data.user;
  }

  return null;
};

export const selectCurrentClient = (state): CurrentClientDTO => {
  if (isAdviserArea(state)) {
    return state.AdviserArea.currentClient;
  }

  if (isConsumerArea(state)) {
    return state.ConsumerArea.client;
  }

  return ADV.data.user;
};

export const isOrionClientSelector = state => {
  const currentClient = currentClientSelector(state);

  return currentClient ? currentClient.IsOrionClient : false;
};

export const showRiskToleranceWorkflowSelector = state => {
  if (isAdviserArea(state)) {
    return advisorShowRiskToleranceWorkflowSelector(state);
  }
  if (isConsumerArea(state)) {
    return state.ConsumerArea.showRiskToleranceWorkflow;
  }
  return false;
};

export const hasCreditMonitoringSelector = state => {
  if (isAdviserArea(state)) {
    return advisorHasCreditMonitoringSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerHasCreditMonitoringSelector(state);
  }
  return false;
};

export const hasAccessToLinkAccountsSelector = state => {
  if (isAdviserArea(state)) {
    return adviserHasDataAggregationSelector(state);
  }
  if (isConsumerArea(state)) {
    return !consumerOptOutsSelector(state).includes('LinkAccounts');
  }
  return false;
};

export const showOrionPerformanceSelector = state =>
  isAdviserArea(state) || consumerOptOutsSelector(state).indexOf('Performance') === -1;

export const hasAccessToManagedAccountsSelector = (state): boolean => {
  if (isAdviserArea(state)) {
    return adviserShowManagedAccountsSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowManagedAccountsSelector(state);
  }
  return false;
};

export const hasAccessToDocumentVaultSelector = state => {
  if (isAdviserArea(state)) {
    return isDocumentVaultVisibleSelector(state);
  }

  if (isConsumerArea(state)) {
    return showDocumentVaultSelector(state);
  }

  if (isPdfArea(state)) {
    return selectHasDocumentVaultAccess(state);
  }

  return false;
};

export const showTransactionsSelector = state => {
  if (isAdviserArea(state)) {
    return true;
  }
  if (isConsumerArea(state)) {
    return consumerShowTransactionsSelector(state);
  }
  return false;
};

export const showNetWorthSelector = state => {
  if (isAdviserArea(state)) {
    return true;
  }
  if (isConsumerArea(state)) {
    return consumerShowNetWorthSelector(state);
  }
  return false;
};

export const showMgpIntegrationSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowMgpIntegrationSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowMgpIntegrationSelector(state);
  }
  return false;
};

export const isDocumentVaultReadonlySelector = state => {
  if (isAdviserArea(state)) {
    return advisorIsDocumentVaultReadonlySelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerIsDocumentVaultReadonlySelector(state);
  }
  return false;
};

export const showEMoneyIntegrationSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowEMoneyIntegrationSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowEMoneyIntegrationSelector(state);
  }
  return false;
};

export const showSchwabIntegrationSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowSchwabIntegrationSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowSchwabIntegrationSelector(state);
  }
  return false;
};

export const selectHasRightCapitalAccess = state => {
  if (isAdviserArea(state)) {
    return selectAdvisorHasRightCapitalAccess(state);
  }

  if (isConsumerArea(state)) {
    return selectConsumerHasRightCapitalAccess(state);
  }

  return false;
};

export const showRISAIntegrationSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowRISAIntegrationSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowRISAIntegrationSelector(state);
  }
  return false;
};

export const showOrionTradingSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowOrionTradingSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowOrionTradingSelector(state);
  }
  return false;
};

export const hasPlaidSelector = state => {
  if (isAdviserArea(state)) {
    return advisorHasPlaidSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerHasPlaidSelector(state);
  }
  return false;
};

export const selectHasAkoya = state => {
  if (isAdviserArea(state)) {
    return adviserSelectHasAkoya(state);
  }
  if (isConsumerArea(state)) {
    return consumerSelectHasAkoya(state);
  }
  return false;
};

export const groupAccountsBySelector = state => {
  if (isAdviserArea(state)) {
    return adviserAccountsGroupingSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerAccountsGroupingSelector(state);
  }
  return 1; // Account type
};

export const hasDataReconciliationSelector = state => {
  if (isAdviserArea(state)) {
    return advisorHasDataReconciliationSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerHasDataReconciliationSelector(state);
  }
  return false;
};

export const showPortfolioReportsSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowPortfolioReportsSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowPortfolioReportsSelector(state);
  }
  return false;
};

export const showReportsSelector = state => {
  if (isAdviserArea(state)) {
    return true;
  }
  if (isConsumerArea(state)) {
    return consumerShowReportsSelector(state);
  }
  return false;
};

export const isFinancialPlanEnabledSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowFinancialPlanningSelector(state);
  }
  if (isConsumerArea(state)) {
    return (
      consumerIsFinancialPlanEnabledSelector(state) && consumerShowFinancialPlanningSelector(state)
    );
  }
  return false;
};

export const selectIsUserClient = () => {
  return ADV.data.user.Role === UserRole.Client;
};

export const selectIsUserAdvisor = () => {
  return ADV.data.user.Role === UserRole.Advisor;
};

export const showTransactionsPageSelector = state => {
  if (isAdviserArea(state)) {
    return adviserShowTransactionsPageSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerShowTransactionsPageSelector(state);
  }
  return false;
};

export const selectShowExecutiveSummary = state => {
  if (isAdviserArea(state)) {
    return adviserSelectShowExecutiveSummary(state);
  }

  if (isConsumerArea(state)) {
    return consumerSelectShowExecutiveSummary(state);
  }

  return false;
};

export const selectShowApexAcatsTransferFlow = state => {
  if (isAdviserArea(state)) {
    return adviserSelectShowApexAcatsTransferFlow(state);
  }

  if (isConsumerArea(state)) {
    return consumerSelectShowApexAcatsTransferFlow(state);
  }

  return false;
};

export const hasEStatementSettingsSelector = state => {
  if (isAdviserArea(state)) {
    return adviserHasEStatementSettingsSelector(state);
  }
  if (isConsumerArea(state)) {
    return consumerHasEStatementSettingsSelector(state);
  }

  return false;
};

export const selectRiskToleranceQuestionnaire = (state: any): RiskToleranceQuestionnaireType => {
  if (isConsumerArea(state)) {
    return selectConsumerRiskToleranceQuestionnaire(state);
  }

  return selectAdvisorRiskToleranceQuestionnaire(state);
};

export const selectIsHiddenLeversRTQ = (state: any): boolean => {
  const riskToleranceQuestionnaire = selectRiskToleranceQuestionnaire(state);

  return riskToleranceQuestionnaire === RiskToleranceQuestionnaireType.HiddenLevers;
};

export const selectCanAccessPersonalFinances = state => {
  if (isConsumerArea(state)) {
    return consumerSelectCanAccessPersonalFinances(state);
  }

  return true;
};

export const selectOrgLogo = state => {
  if (isConsumerArea(state)) {
    return selectConsumerOrgLogo(state);
  }
  return selectAdvisorOrgLogo(state);
};

export const hasNetWorthAccessSelector = state => {
  if (isConsumerArea(state)) {
    return !consumerOptOutsSelector(state).includes('NetWorth');
  }

  return true;
};

export const selectAssetBucketingHeadline = (state: any): string => {
  return isConsumerArea(state)
    ? ADV.data.user.AssetBucketingHeadline
    : selectAdvisorAssetBucketingHeadline(state);
};

export const selectPortalName = (state: any): string => {
  if (isConsumerArea(state)) {
    return selectConsumerPortalName(state);
  }

  if (isAdviserArea(state)) {
    return selectAdvisorPortalName(state);
  }

  return 'Orion Planning';
};

export const selectFeatureFlags = (state: any): FeatureFlag[] => {
  if (isAdviserArea(state)) {
    return selectAdvisorFeatureFlags(state);
  }

  if (isPdfArea(state)) {
    return pdfSelectFeatureFlags(state);
  }

  return selectConsumerFeatureFlags(state);
};

export const selectFooterDisclosures = (state: any): FooterDisclosures[] =>
  isConsumerArea(state)
    ? selectConsumerFooterDisclosures(state)
    : selectAdvisorFooterDisclosures(state);

export const selectIsCanadian = (state: any): boolean => {
  const { IsCanadian } = selectCurrentClient(state);

  return IsCanadian;
};

export const selectLanguagePreference = (state: any): LanguageCode | null =>
  isConsumerArea(state) ? selectConsumerLanguagePreference(state) : LanguageCode.EnglishAmerican;

export const selectHasBeFi20 = (state: any): boolean => {
  if (isConsumerArea(state)) {
    return consumerSelectHasBeFi20(state);
  }

  if (isAdviserArea(state)) {
    return advisorSelectHasBeFi20(state);
  }

  return false;
};

export const selectHasPulseCheck = (state: any): boolean => {
  if (isConsumerArea(state)) {
    return consumerSelectHasPulseCheck(state);
  }

  if (isAdviserArea(state)) {
    return advisorSelectHasPulseCheck(state);
  }

  return false;
};

export const selectShowRelatedHouseholds = (state: any): ShowRelatedHouseholdsOption => {
  if (isConsumerArea(state)) {
    return consumerSelectShowRelatedHouseholds(state);
  }

  return ShowRelatedHouseholdsOption.Hide;
};

export const selectShowAlternativeDetail = (state: any): boolean => {
  if (isConsumerArea(state)) {
    return consumerSelectShowAlternativeDetail(state);
  }

  if (isAdviserArea(state)) {
    return advisorSelectShowAlternativeDetail(state);
  }

  return false;
};

export const selectOnboardingWorkflow = (state: any): OnboardingWorkflow => {
  if (isConsumerArea(state)) {
    return consumerSelectOnboardingWorkflow(state);
  }

  return OnboardingWorkflow.None;
};

export const selectExternalAccountProviderAccess = (
  state: any,
): ExternalAccountProviderAccessDTO[] => {
  const { ExternalAccountProviderAccess } = selectCurrentClient(state);

  return ExternalAccountProviderAccess;
};

export const selectHasAccessToProfile = (state: any): boolean => {
  if (isConsumerArea(state)) {
    return selectIsProfileEnable(state);
  }

  return isFinancialPlanEnabledSelector(state);
};

export const selectLandingPerformanceReportingTab = (state: any): string => {
  if (isConsumerArea(state)) {
    const componentName = selectComponentName(state);
    const componentProps = selectComponentProps(state);

    return componentName === 'AccountPerformanceReporting' && componentProps?.tabSlug
      ? componentProps.tabSlug
      : 'summary';
  }

  return 'summary';
};

export const selectHasAdvisorAssetBucketingLayoutAccess = (state: any): boolean => {
  return isConsumerArea(state)
    ? ADV.data.user.hasAdvisorAssetBucketingLayoutAccess
    : advisorSelectHasAdvisorAssetBucketingLayoutAccess(state);
};

export const selectOrionWidgetsChartType = (state: any): ChartType => {
  if (isConsumerArea(state)) {
    return consumerSelectOrionWidgetsChartType(state);
  }

  if (isAdviserArea(state)) {
    return adviserSelectOrionWidgetsChartType(state);
  }

  return ChartType.Bar;
};

type AkoyaPhase = 'none' | 'phase2' | 'phase3';

export const selectAkoyaPhase = createSelector(
  isOrionClientSelector,
  hasAccessToLinkAccountsSelector,
  selectHasAkoya,
  selectExternalAccountProviderAccess,
  (isOrionClient, hasAccessToLinkAccounts, hasAkoya, externalAccountProviders): AkoyaPhase => {
    if (externalAccountProviders.length === 0) {
      return 'none';
    }

    // Orion clients
    if (isOrionClient) {
      return hasAccessToLinkAccounts && hasAkoya ? 'phase3' : 'phase2';
    }

    // Legacy clients
    return hasAccessToLinkAccounts && hasAkoya ? 'phase3' : 'none';
  },
);

export const selectHasMobileNavigation = (state: any): boolean => {
  const { isMobile } = getViewport();

  const { HasMobileNavigation } = selectCurrentClient(state);

  return HasMobileNavigation && isMobile;
};

export const selectHasAccessEditPlans = state => {
  if (isConsumerArea(state)) {
    return !consumerOptOutsSelector(state).includes('MultiPlansSelectionEditMode');
  }

  return true;
};

export const selectHasFirmSettingsAccess = (state: any): boolean => {
  if (isAdviserArea(state)) {
    return adviserFirmSettingsAccessSelector(state);
  }

  return false;
};

export const selectTermsAndConditionsUrl = () => ADV?.info?.websiteTermsAndConditionsUrl;

export const selectShowIncomeTab = (state: any): boolean => {
  const { ShowIncomeTab } = selectCurrentClient(state);

  return ShowIncomeTab;
};
