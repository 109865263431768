import styled from 'styled-components';
import { variant } from 'styled-system';
import { Box } from '../Box/Box';
import {
  BorderProps,
  ColorProps,
  PaddingProps,
  PropsWithPseudo,
  TypographyProps,
} from '../../../theme/styledProps';

export type AlertVariant = 'neutral' | 'info' | 'success' | 'warning' | 'error';

type Pseudo = '&::before';

interface StyledAlertProps {
  variant: AlertVariant;
  isFullHeight?: boolean;
}

export const StyledAlert = styled(Box)<StyledAlertProps>(
  ({ theme, isFullHeight }) => ({
    fontFamily: '"Inter", sans-serif',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.radii.medium,
    display: 'flex',
    flexDirection: 'row',
    alignItems: isFullHeight ? 'flex-start' : 'center',
    fontSize: 14,
    color: theme.colors.neutral['700'],
    height: isFullHeight ? '100%' : 'initial',

    '&::before': {
      fontFamily: '"Font Awesome 5 Pro"',
      fontSize: 16,
      fontWeight: 600,
      position: 'absolute',
      marginRight: 16,
      top: 16,
      left: 16,
      lineHeight: '20px',
    },
  }),

  variant<
    PropsWithPseudo<
      ColorProps & BorderProps & PaddingProps & TypographyProps & { content?: string },
      Pseudo
    >,
    AlertVariant
  >({
    variants: {
      neutral: {
        borderColor: 'neutral.300',
        bg: 'neutral.100',
        pl: '48',

        '&::before': {
          content: "'\f058'",
          color: 'success.500',
        },
      },

      info: {
        borderColor: 'info.300',
        bg: 'info.50',
        pl: '48',
        color: 'info.600',

        '&::before': {
          content: "'\f05a'",
          fontWeight: 400,
          color: 'info.500',
        },
      },

      success: {
        borderColor: 'success.300',
        bg: 'success.50',
        pl: '48',
        color: 'success.600',

        '&::before': {
          content: "'\f058'",
          color: 'success.500',
        },
      },

      warning: {
        borderColor: 'warning.300',
        bg: 'warning.50',
        pl: '48',
        color: 'neutral.700',

        '&::before': {
          content: "'\f071'",
          color: 'warning.400',
        },
      },

      error: {
        borderColor: 'error.300',
        bg: 'error.50',
        pl: '48',
        color: 'error.600',

        '&::before': {
          content: "'\f2f0'",
          color: 'error.300',
        },
      },
    },
  }),
);
