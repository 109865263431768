import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Text } from '../../../../../../CommonComponents/OPL/Text/Text';
import { Input } from '../../../../../../CommonComponents/OPL/Input/Input';
import { ResendCode } from '../ResendCode/ResendCode';
import { Button } from '../../../../../../CommonComponents/OPL/Button/Button';
import { GENERIC_ERROR, LoginFn, SendCodeFn, TwoFactorAuthMethod } from '../../../../Login.meta';
import { validation } from './SendCodeForm.meta';
import { toast } from '../../../../../../CommonComponents/OPL/Toast/Toast';
import { ApiError } from '../../../../../../errors/ApiError';

interface Props {
  method: TwoFactorAuthMethod;
  setMethod: (method: TwoFactorAuthMethod | null) => void;
  login: LoginFn;
  sendCode: SendCodeFn;
}

export const SendCodeForm = ({ method, setMethod, login, sendCode }: Props) => {
  const { register, formState, handleSubmit, setError } = useForm({
    defaultValues: {
      mfaCode: '',
    },
    resolver: yupResolver(validation),
  });

  const onSubmit = handleSubmit(async data => {
    try {
      await login(data);
    } catch (error) {
      if (error instanceof ApiError && /code.*is.*invalid/i.test(error.message)) {
        setError('mfaCode', { type: 'apiError', message: error.message }, { shouldFocus: true });
      } else {
        toast.error(GENERIC_ERROR);
      }

      console.error(error);
    }
  });

  return (
    <form onSubmit={onSubmit} aria-label="Send 2FA code">
      <Text as="h1" fontSize="h4" fontWeight={600} color="neutral.600" mb="16">
        Enter Verification Code
      </Text>

      <Text as="p" fontWeight={500} color="neutral.500" mb="32">
        {method.type === 'Phone'
          ? `We sent a 6-digit verification code via text message to ${method.mask}`
          : `We sent a 6-digit verification code via email to ${method.mask}`}
      </Text>

      <Input
        {...register('mfaCode')}
        type="text"
        label="Enter Code"
        formVariant={formState.errors.mfaCode && 'error'}
        helperText={formState.errors.mfaCode?.message}
        autoComplete="off"
        mb="12"
      />

      <ResendCode method={method} sendCode={sendCode} />

      <Button mt="32" width="100%" isLoading={formState.isSubmitting} type="submit">
        Verify
      </Button>

      <Button variant="link" mt="16" onClick={() => setMethod(null)}>
        Log in another way
      </Button>
    </form>
  );
};
