import { useSelector } from 'react-redux';
import { isPdfArea } from '../../CommonSelectors/CommonAreaSelector';

export const useIsPdf = () => {
  try {
    return useSelector(isPdfArea);
  } catch {
    return false;
  }
};
