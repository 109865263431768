import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { Placement } from 'tippy.js';
import { Text } from '../Text/Text';
import { Wrapper, TooltipVariant } from './Tooltip.styles';
import { MAX_Z_INDEX } from '../../../theme/theme';

export interface TooltipProps extends TippyProps {
  placement?: Placement;
  content: string | React.ReactNode;
  variant?: TooltipVariant;
  isOverflowWrap?: boolean;
  isAlt?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  placement = 'auto',
  delay = 0,
  content,
  variant,
  isOverflowWrap,
  isAlt,
  ...rest
}) => {
  return (
    <Tippy
      placement={placement}
      delay={delay}
      render={attrs => (
        <Wrapper variant={variant} isAlt={isAlt} {...attrs}>
          {typeof content === 'string' || typeof content === 'number' ? (
            <Text
              isOverflowWrap={isOverflowWrap}
              fontWeight={600}
              fontSize="small"
              color={isAlt ? 'neutral.600' : 'white'}
            >
              {content}
            </Text>
          ) : (
            content
          )}
        </Wrapper>
      )}
      zIndex={MAX_Z_INDEX}
      {...rest}
    >
      {children}
    </Tippy>
  );
};
