import React from 'react';
import { HelperTextVariant, StyledHelperText } from './HelperText.styles';
import { MarginProps } from '../../../theme/styledProps';

export interface HelperTextProps extends MarginProps {
  variant?: HelperTextVariant;
  descriptionId?: string;
}

export const HelperText: React.FC<HelperTextProps> = ({
  variant,
  descriptionId,
  children,
  ...rest
}) => {
  return (
    <StyledHelperText id={descriptionId} variant={variant as any} {...rest}>
      {children}
    </StyledHelperText>
  );
};
