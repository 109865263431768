import React from 'react';
import PropTypes from 'prop-types';

const isMobileDevice = () =>
  typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;

export const MOBILE_BREAKPOINT = 768;

export const getViewportWidth = () => window.screen.width;
export const getViewportHeight = () => window.screen.height;

export const getViewport = () => {
  const width = getViewportWidth();
  const height = getViewportHeight();

  return {
    width,
    height,
    isMobile: isMobileDevice() && Math.min(width, height) <= MOBILE_BREAKPOINT,
  };
};

export default WrappedComponent => {
  class WithResponsiveness extends React.Component {
    constructor(props) {
      super(props);
      this.onResizeListener = this.onResizeListener.bind(this);
      this.state = getViewport();
    }

    componentDidMount() {
      window.addEventListener('resize', this.onResizeListener);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResizeListener);
    }

    onResizeListener() {
      const viewport = getViewport();
      const { isMobile } = this.state;

      if (viewport.isMobile !== isMobile) {
        const { onIsMobileWillChange } = this.props;

        if (onIsMobileWillChange) {
          onIsMobileWillChange();
        }
      }
      this.setState(viewport);
    }

    render() {
      const { width, height, isMobile } = this.state;
      const { IsOrionClient } = ADV.data.user;

      return (
        <WrappedComponent
          {...this.props}
          viewport={{
            width,
            height,
            isMobile: IsOrionClient ? false : isMobile,
          }}
        />
      );
    }
  }

  WithResponsiveness.propTypes = {
    onIsMobileWillChange: PropTypes.func.isRequired,
  };

  return WithResponsiveness;
};
