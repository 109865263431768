import styled from 'styled-components';
import { Box } from '../Box/Box';

export interface Props {
  hasBorder?: boolean;
}

export const ModalFooter = styled(Box)<Props>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.bg};
  border-bottom-left-radius: ${({ theme }) => `${theme.radii.xl}px`};
  border-bottom-right-radius: ${({ theme }) => `${theme.radii.xl}px`};
  border-top: ${({ hasBorder, theme }) =>
    hasBorder ? `1px solid ${theme.colors.neutral['200']}` : undefined};
`;
