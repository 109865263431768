import styled from 'styled-components';
import { margin, typography, variant } from 'styled-system';
import {
  LayoutProps,
  TypographyProps,
  PaddingProps,
  ColorProps,
  PropsWithPseudo,
  SpaceProps,
} from '../../../theme/styledProps';
import { overflowWrapStyle } from '../Text/Text';

export const LinkText = styled.span<{ isOverflowWrap?: boolean }>(({ isOverflowWrap }) => ({
  textDecoration: 'none',
  ...(isOverflowWrap ? overflowWrapStyle : {}),
}));

export type Pseudo = '&:hover' | '&:not(.disabled):hover' | '&:not(.disabled):active';

export type LinkVariant = 'default' | 'button' | 'subtleInactive';

export type LinkSize = 'small' | 'medium' | 'large';

interface StyledLinkProps {
  variant: LinkVariant;
  size?: LinkSize;
}

export const StyledLink = styled.a<StyledLinkProps>(
  ({ theme }) => ({
    fontFamily: theme.fontFamily,
    fontWeight: 500,
    fontSize: theme.fontSizes.body,
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    outline: 'none',
    borderRadius: theme.radii.medium,
    transition: `color ${theme.transitions.fast}`,

    '&[data-focus-visible-added]:focus': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.primary['300']}, inset 0px 0px 0px 1px ${theme.colors.primary['500']}`,
      zIndex: 1,
    },
  }),

  ({ theme }) =>
    variant<
      PropsWithPseudo<LayoutProps & TypographyProps & PaddingProps & ColorProps, Pseudo>,
      LinkVariant
    >({
      variants: {
        default: {
          color: theme.colors.primary['500'],
          py: 2,

          '&:hover': {
            color: theme.colors.primary['600'],
          },
        },

        button: {
          bg: theme.colors.primary['500'],
          color: theme.colors.bg,
          px: 16,
          height: 36,
          lineHeight: '36px',

          '&:hover': {
            color: theme.colors.bg,
            bg: theme.colors.primary['600'],
          },
        },

        subtleInactive: {
          bg: 'transparent',
          color: 'neutral.600',

          px: 12,
          height: 36,
          lineHeight: '36px',

          '&:not(.disabled):hover': {
            bg: 'neutral.200',
          },

          '&:not(.disabled):active': {
            bg: 'neutral.300',
          },
        },
      },
    }),

  variant<LayoutProps & SpaceProps & TypographyProps, LinkSize>({
    prop: 'size',
    variants: {
      small: {
        px: '8',
        height: 24,
        fontSize: 'small',
        lineHeight: '24px',
      },

      medium: {
        height: 36,
        fontSize: 'body',
        lineHeight: '36px',
      },

      large: {
        height: 48,
        fontSize: 'body',
        lineHeight: '48px',
      },
    },
  }),

  typography,
  margin,
);
