import React, { useState } from 'react';
import { GENERIC_ERROR, LoginFn, SendCodeFn, TwoFactorAuthMethod } from '../../Login.meta';
import { Text } from '../../../../CommonComponents/OPL/Text/Text';
import { Box } from '../../../../CommonComponents/OPL/Box/Box';
import { MethodButton } from './TwoFactorAuth.styles';
import { Icon } from '../../../../CommonComponents/OPL/Icon/Icon';
import { Button } from '../../../../CommonComponents/OPL/Button/Button';
import { toast } from '../../../../CommonComponents/OPL/Toast/Toast';
import { SendCodeForm } from './components/SendCodeForm/SendCodeForm';
import { Loading } from '../../../../CommonComponents/OPL/Loading/Loading';

interface Props {
  methods: TwoFactorAuthMethod[];
  login: LoginFn;
  sendCode: SendCodeFn;
}

export const TwoFactorAuth = ({ methods, login, sendCode }: Props) => {
  const [userHasNoAccess, setUserHasNoAccess] = useState(false);

  const [methodIdSending, setMethodIdSending] = useState<string | null>(null);

  const [selectedMethod, setSelectedMethod] = useState<TwoFactorAuthMethod | null>(null);

  const handleSendCode = async (method: TwoFactorAuthMethod) => {
    try {
      setMethodIdSending(method.id);

      await sendCode({ methodId: method.id });

      setSelectedMethod(method);
    } catch (error) {
      console.error(error);

      toast.error(GENERIC_ERROR);
    } finally {
      setMethodIdSending(null);
    }
  };

  if (selectedMethod) {
    return (
      <SendCodeForm
        method={selectedMethod}
        setMethod={setSelectedMethod}
        login={login}
        sendCode={sendCode}
      />
    );
  }

  return (
    <>
      <Text as="h1" fontSize="h4" fontWeight={600} color="neutral.600" mb="16">
        Verify Your Identity
      </Text>

      <Text as="p" fontWeight={500} color="neutral.500" mb="32">
        {userHasNoAccess
          ? 'To regain access to your account, please contact your advisor'
          : 'Please select a delivery method for your 6-digit verification code:'}
      </Text>

      <Box as="ul" css="list-style: none" p="0">
        {methods.map(method => (
          <li key={method.id}>
            <MethodButton onClick={() => handleSendCode(method)} disabled={!!methodIdSending}>
              <Box display="flex" justifyContent="center" width={24}>
                <Icon
                  className={
                    method.type === 'Phone' ? 'fas fa-mobile-android-alt' : 'far fa-envelope'
                  }
                  fontSize="h3"
                  color="primary.500"
                />
              </Box>

              <Box flex={1} mx="16">
                <Text as="p" fontWeight={600} fontSize="h6" textAlign="left">
                  {method.type === 'Phone' ? 'Text Message (SMS)' : 'Email'}
                </Text>
                <Text color="neutral.500" textAlign="left">
                  {method.type === 'Phone'
                    ? `Text a code to ${method.mask}`
                    : `Email a code to ${method.mask}`}
                </Text>
              </Box>

              {methodIdSending === method.id ? (
                <Loading size="small" />
              ) : (
                <Icon className="far fa-chevron-right" fontSize="h6" color="primary.500" />
              )}
            </MethodButton>
          </li>
        ))}
      </Box>

      {userHasNoAccess ? (
        <Button variant="link" mt="32" onClick={() => window.location.reload()}>
          Login to another account
        </Button>
      ) : (
        <Button variant="link" mt="32" onClick={() => setUserHasNoAccess(true)}>
          {methods.length > 1
            ? "I don't have access to either of these"
            : "I don't have access to this"}
        </Button>
      )}
    </>
  );
};
