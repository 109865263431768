import React from 'react';
import { margin } from 'styled-system';
import styled from '../../../theme/theme';
import { MarginProps } from '../../../theme/styledProps';

export interface WrapperProps extends MarginProps, React.ComponentPropsWithoutRef<'div'> {}

/**
 * Use this component as a wrapper that accepts margin props
 */
export const Wrapper = styled.div<WrapperProps>(margin);
