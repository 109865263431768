import React from 'react';
import { Text } from '../Text/Text';
import { MarginProps } from '../../../theme/styledProps';
import { Tooltip } from '../Tooltip/Tooltip';
import { Icon } from '../Icon/Icon';

const colorMap = {
  success: 'success.700',
  error: 'error.700',
};

type LabelVariant = 'success' | 'warning' | 'error';

export interface LabelProps extends MarginProps {
  id?: string;
  variant?: LabelVariant;
  htmlFor?: string;
  tooltip?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = ({ htmlFor, variant, children, tooltip, ...rest }) => {
  return (
    <>
      <Text
        as="label"
        variant="formLabel"
        htmlFor={htmlFor}
        color={(variant && colorMap[variant]) || 'neutral.600'}
        {...rest}
      >
        {children}
      </Text>

      {tooltip && (
        <Tooltip content={tooltip} placement="right-end">
          <Icon className="far fa-info-circle" color="neutral.500" fontSize="small" ml="4" />
        </Tooltip>
      )}
    </>
  );
};
