import * as styledComponents from 'styled-components';
import { useContext } from 'react';
import { tint, shade } from 'polished';
import { palette } from './palette';

const shadeWeights = {
  tints: [
    { weight: '50', tintPercentage: 0.9 },
    { weight: '100', tintPercentage: 0.8 },
    { weight: '200', tintPercentage: 0.7 },
    { weight: '300', tintPercentage: 0.5 },
    { weight: '400', tintPercentage: 0.3 },
  ],
  shades: [
    { weight: '600', shadePercentage: 0.2 },
    { weight: '700', shadePercentage: 0.4 },
    { weight: '800', shadePercentage: 0.6 },
    { weight: '900', shadePercentage: 0.8 },
  ],
};

export const isValidHex = (hex: string | null) => {
  if (hex === null) {
    return false;
  }
  return hex?.length === 7 ? /^#[0-9A-F]{6}$/i.test(hex) : /^#[0-9A-F]{3}$/i.test(hex);
};

export const generateColorShades = (hex: string) => {
  if (!isValidHex(hex)) {
    return palette.blue;
  }

  const tints = shadeWeights.tints.reduce((acc, { weight, tintPercentage }) => {
    acc[weight] = tint(tintPercentage, hex);

    return acc;
  }, {} as Record<string, string>);

  const shades = shadeWeights.shades.reduce((acc, { weight, shadePercentage }) => {
    acc[weight] = shade(shadePercentage, hex);

    return acc;
  }, {} as Record<string, string>);

  return {
    ...tints,
    '500': hex,
    ...shades,
  };
};

export const theme = {
  colors: {
    neutral: palette.gray,
    warning: palette.yellow,
    error: palette.red,
    success: palette.green,
    info: palette.blue,
    fg: palette.black,
    bg: palette.white,
    primary:
      window.ADV && ADV.info.customColor ? generateColorShades(ADV.info.customColor) : palette.blue,
    ...palette,
  },

  space: {
    '0': 0,
    '2': 2,
    '4': 4,
    '8': 8,
    '12': 12,
    '16': 16,
    '24': 24,
    '32': 32,
    '40': 40,
    '48': 48,
    '56': 56,
  },

  fontSizes: {
    extraSmall: 8,
    small: 12,
    body: 14,
    h6: 16,
    h5: 18,
    h4: 20,
    h3: 24,
    h2: 32,
    h1: 40,
    large: 48,
    xl: 60,
    xxl: 72,
  },

  lineHeights: {
    small: '16px',
    body: '20px',
    h6: '24px',
    h5: '24px',
    h4: '32px',
    h3: '32px',
    h2: '48px',
    h1: '56px',
    large: '72px',
    xl: '88px',
    xxl: '112px',
  },

  fontWeights: {
    400: 400,
    500: 500,
    600: 600,
    700: 700,
  },

  fontFamily: '"Inter", sans-serif',

  radii: {
    none: 0,
    small: 2,
    medium: 4,
    large: 6,
    xl: 8,
    xxl: 16,
    full: 999,
  },

  letterSpacings: {
    normal: '0',
    wide: '0.5px',
  },

  shadows: {
    '-100': 'inset 0px 2px 4px rgba(55, 65, 81, 0.06)',
    '100': '0px 1px 1px rgba(55, 65, 81, 0.06)',
    '200': '0px 1px 2px rgba(55, 65, 81, 0.08)',
    '300': '0px 1px 2px rgba(55, 65, 81, 0.06), 0px 1px 3px rgba(55, 65, 81, 0.1)',
    '400': '0px 0px 4px rgba(55, 65, 81, 0.06), 0px 2px 6px rgba(55, 65, 81, 0.1)',
    '500': '0px 8px 16px rgba(55, 65, 81, 0.1), 0px 2px 6px rgba(55, 65, 81, 0.05)',
    '600': '0px 12px 25px rgba(55, 65, 81, 0.1), 0px 8px 10px rgba(55, 65, 81, 0.04)',
    '700': '0px 24px 50px rgba(55, 65, 81, 0.25)',
  },

  transitions: {
    slow: '300ms ease-in-out',
    normal: '200ms ease-in-out',
    fast: '100ms ease-in-out',
  },

  background: 'linear-gradient(180deg, rgb(244,245,247), rgb(255,255,255) 100%);',

  breakpoints: ['640px'],
};

export type Theme = typeof theme;

export type VariantColor = 'neutral' | 'primary' | 'success' | 'warning' | 'error' | 'info';

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

const { default: styled, css, ThemeProvider, createGlobalStyle } = styledComponents;

const useTheme = () => {
  return useContext<Theme>(styledComponents.ThemeContext);
};

export { ThemeProvider, css, useTheme, createGlobalStyle };

export default styled;

export const MAX_Z_INDEX = 2147483647;
