import React from 'react';
import { LinkText, StyledLink, LinkVariant, LinkSize } from './Link.styles';
import { Icon } from '../Icon/Icon';
import { MarginProps, TypographyProps } from '../../../theme/styledProps';

export interface LinkProps
  extends React.ComponentPropsWithoutRef<'a'>,
    TypographyProps,
    MarginProps {
  isExternal?: boolean;
  variant?: LinkVariant;
  size?: LinkSize;
  isOverflowWrap?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  isExternal,
  children,
  variant = 'default',
  size = 'medium',
  rel = 'noopener noreferrer',
  isOverflowWrap,
  ...rest
}) => {
  return (
    <StyledLink
      rel={rel}
      target="_blank"
      variant={variant}
      size={variant === 'button' ? size : undefined}
      {...rest}
    >
      <LinkText isOverflowWrap={isOverflowWrap}>{children}</LinkText>

      {isExternal && <Icon className="far fa-external-link" ml="4" />}
    </StyledLink>
  );
};
