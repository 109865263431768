import React from 'react';
import { WidthProps } from 'styled-system';
import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  StyledButton,
  ButtonText,
  LeftAddon,
  RightAddon,
  ButtonShape,
} from './Button.styles';
import { Loading } from '../Loading/Loading';
import { LoadingVariant } from '../Loading/Loading.styles';
import { MarginProps } from '../../../theme/styledProps';
import { VariantColor } from '../../../theme/theme';

const getLoadingVariant = (variant: ButtonVariant): LoadingVariant => {
  switch (variant) {
    case 'primary':
    case 'error':
      return 'light';
    case 'errorSecondary':
      return 'error';
    default:
      return 'dark';
  }
};

export interface Props extends React.ComponentPropsWithoutRef<'button'>, MarginProps, WidthProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  /**
   * Use this prop to change the color of link buttons.
   */
  variantColor?: VariantColor;
  isLoading?: boolean;
  isIconButton?: boolean;
  /**
   * Use font-awesome classes to render icons (e.g. fas fa-home)
   */
  leftIcon?: string;
  /**
   * Use font-awesome classes to render icons (e.g. fas fa-home)
   */
  rightIcon?: string;
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  isTruncated?: boolean;

  shape?: ButtonShape;
}

/**
 * Primary UI component for user interaction
 */
export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primary',
      size = 'medium',
      leftIcon,
      rightIcon,
      children,
      disabled,
      isLoading,
      isIconButton,
      type = 'button',
      leftAddon,
      rightAddon,
      isTruncated,
      shape = 'square',
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledButton
        variant={variant}
        size={size}
        disabled={disabled || isLoading}
        type={type}
        ref={ref}
        isTruncated={isTruncated}
        shape={shape}
        {...rest}
      >
        {isLoading && (
          <Loading variant={getLoadingVariant(variant)} size="small" css="position: absolute" />
        )}

        {leftAddon && <LeftAddon isLoading={isLoading}>{leftAddon}</LeftAddon>}

        {leftIcon && (
          <ButtonIcon
            className={leftIcon}
            isLeft={!!children}
            isLoading={isLoading}
            isIconButton={isIconButton}
            size={size}
          />
        )}

        <ButtonText isLoading={isLoading} isTruncated={isTruncated}>
          {children}
        </ButtonText>

        {rightIcon && (
          <ButtonIcon
            className={rightIcon}
            isRight={!!children}
            isLoading={isLoading}
            size={size}
          />
        )}

        {rightAddon && <RightAddon isLoading={isLoading}>{rightAddon}</RightAddon>}
      </StyledButton>
    );
  },
);
