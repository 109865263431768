import React, { useEffect, useRef } from 'react';
import { Wrapper, WrapperProps } from '../Wrapper/Wrapper';
import { AlertVariant, StyledAlert } from './Alert.styles';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import { ActionButton } from './components/ActionButton/ActionButton';
import { ExtraActionButton } from './components/ExtraActionButton/ExtraActionButton';
import { CloseButton } from './components/CloseButton/CloseButton';

type Action = {
  text: string;
  action(): void;
};

export interface Props extends WrapperProps {
  title?: string;
  message?: React.ReactNode;
  variant?: AlertVariant;
  onRequestClose?(): void;
  actions?: Action[];
  extraAction?: Action;
  scrollIntoView?: boolean;
  scrollIntoViewOptions?: ScrollIntoViewOptions;
  isFullHeight?: boolean;
}

export const Alert: React.FC<Props> = ({
  variant = 'neutral',
  title,
  message,
  onRequestClose,
  actions,
  extraAction,
  scrollIntoView = true,
  scrollIntoViewOptions,
  isFullHeight,
  children,
  ...rest
}) => {
  const alertRef = useRef<HTMLDivElement>(null);

  const scrollIntoViewOptionsRef = useRef<ScrollIntoViewOptions>(scrollIntoViewOptions || null);

  useEffect(() => {
    if (scrollIntoView && alertRef.current) {
      const options = scrollIntoViewOptionsRef.current || {
        block: 'end',
        behavior: 'smooth',
      };

      alertRef.current.scrollIntoView(options);
    }
  }, [message, scrollIntoView]);

  return (
    <Wrapper
      ref={alertRef}
      role="alert"
      {...rest}
      css={{ height: isFullHeight ? '100%' : 'initial' }}
    >
      <StyledAlert
        variant={variant}
        title={title}
        pl="48"
        pr={onRequestClose ? '48' : '16'}
        py="16"
        isFullHeight={isFullHeight}
      >
        <Box display="flex" flexDirection="column" flex={1} mr="16">
          {!!title && (
            <Text fontWeight={600} color="inherit" mb={message ? '4' : '0'}>
              {title}
            </Text>
          )}

          {message ? <Text color="inherit">{message}</Text> : children}

          {actions && (
            <Box display="flex" mt="8" ml={-16}>
              {actions.map(({ text, action }) => (
                <ActionButton key={text} alertVariant={variant} onClick={action} mr="8">
                  {text}
                </ActionButton>
              ))}
            </Box>
          )}
        </Box>

        {extraAction && (
          <ExtraActionButton
            key={extraAction.text}
            alertVariant={variant}
            onClick={extraAction.action}
            rightIcon="fas fa-arrow-right"
            mr={onRequestClose ? '16' : '0'}
          >
            {extraAction.text}
          </ExtraActionButton>
        )}

        {onRequestClose && (
          <Box position="absolute" right={16}>
            <CloseButton alertVariant={variant} onClick={onRequestClose} />
          </Box>
        )}
      </StyledAlert>
    </Wrapper>
  );
};
