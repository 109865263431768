import { MarginProps } from '../../../theme/styledProps';

const marginProps = ['m', 'mx', 'my', 'mt', 'mr', 'mb', 'ml'];

export const extractMarginProps = (props: Record<string, any> = {}): MarginProps => {
  return Object.entries(props).reduce((acc, [key, value]) => {
    if (marginProps.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
