import styled from 'styled-components';
import { variant } from 'styled-system';
import { PaddingProps, TypographyProps } from '../../../theme/styledProps';

export type TooltipVariant = 'chart' | 'slider';

export const Wrapper = styled.div.attrs({
  tabIndex: -1,
})<{ variant?: TooltipVariant; isAlt?: boolean }>`
  padding: 8px;
  background: ${({ theme, isAlt }) =>
    (theme.colors as any).tooltipBg || isAlt
      ? theme.colors.neutral[200]
      : theme.colors.neutral[700]};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(55, 65, 81, 0.06), 0 1px 3px rgba(55, 65, 81, 0.1);
  max-width: 250px;

  ${variant<PaddingProps & TypographyProps, TooltipVariant>({
    variants: {
      chart: {
        py: 4,
        px: 8,
        fontSize: 'small',
      },
      slider: {
        py: 2,
        px: 4,
        fontSize: 'small',
      },
    },
  })}
`;
