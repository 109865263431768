import React from 'react';
import styled from 'styled-components';
import { ForwardRefComponent, HTMLMotionProps } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { color, compose, flexbox, FlexboxProps, layout, space, typography } from 'styled-system';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ColorProps,
  LayoutProps,
  PaddingProps,
  SpaceProps,
  TypographyProps,
} from '../../../theme/styledProps';

import { useIsPdf } from '../../../FinancialPlanOPL/hooks/useIsPdf';

export interface BaseIconProps
  extends TypographyProps,
    ColorProps,
    SpaceProps,
    FlexboxProps,
    PaddingProps,
    LayoutProps {
  as?: ForwardRefComponent<HTMLElement, HTMLMotionProps<'i'>> | string;
}

export const BaseIcon = styled.i<BaseIconProps>(compose(typography, color, space, flexbox, layout));

export const BaseFontAwesome = styled(FontAwesomeIcon)<BaseIconProps>(
  () => ({
    verticalAlign: 'bottom',
  }),
  compose(typography, color, space, flexbox, layout),
);

interface IconProps extends HTMLMotionProps<'i'>, BaseIconProps {}

export const Icon = React.forwardRef<HTMLElement, IconProps>((props, ref) => {
  const isPDF = useIsPdf();

  if (isPDF) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, as, animate, ...rest } = props;

    const icon = (className as string).split(' ') as IconProp;

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return <BaseFontAwesome icon={icon} {...rest} className={className} />;
  }

  return <BaseIcon {...props} ref={ref} />;
});
