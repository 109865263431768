import React, { useRef } from 'react';
import { TextAlignProps } from 'styled-system';
import { useId } from '@reach/auto-id';
import useMeasure from 'react-use/lib/useMeasure';
import composeRefs from '@seznam/compose-react-refs';
import {
  FormVariant,
  InnerText,
  InputIcon,
  InputSizeVariant,
  StyledInput,
  Wrapper,
} from './Input.styles';
import { MarginProps } from '../../../theme/styledProps';
import { HelperText } from '../HelperText/HelperText';
import { Label } from '../Label/Label';
import { Box } from '../Box/Box';
import { Selector } from './components/Selector/Selector';
import { OptionType } from '../Select/BaseSelect/types';
import { extractMarginProps } from '../utils/extractMarginProps';

export interface Props
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>,
    TextAlignProps,
    MarginProps {
  label?: React.ReactNode;
  helperText?: string;
  formVariant?: FormVariant;
  sizeVariant?: InputSizeVariant;
  leftIcon?: string;
  rightIcon?: string;
  innerText?: string;
  tooltip?: React.ReactNode;
  selectorProps?: {
    defaultValue: OptionType<any>;
    options: OptionType<any>[];
    onChange(opt: OptionType<any>): void;
    ariaLabel: string;
  };
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      sizeVariant = 'medium',
      label,
      formVariant,
      helperText,
      id,
      leftIcon,
      rightIcon,
      innerText,
      tooltip,
      selectorProps,
      disabled,
      className,
      ...rest
    },
    ref,
  ) => {
    const inputId = `input--${useId(id)}`;
    const descriptionId = `input--help-text--${useId()}`;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [selectorRef, { width: selectorWidth }] = useMeasure<HTMLDivElement>();

    const [innerTextRef, { width: innerTextWidth }] = useMeasure<HTMLDivElement>();

    return (
      <Wrapper {...extractMarginProps(rest)} className={className}>
        {label && (
          <Label htmlFor={inputId} variant={formVariant} tooltip={tooltip} mb="4">
            {label}
          </Label>
        )}

        <Box position="relative">
          {leftIcon && (
            <InputIcon className={leftIcon} isLeft size={sizeVariant} disabled={disabled} />
          )}

          <StyledInput
            id={inputId}
            formVariant={formVariant}
            sizeVariant={sizeVariant}
            ref={composeRefs(inputRef, ref)}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            disabled={disabled}
            aria-describedby={helperText ? descriptionId : undefined}
            aria-invalid={formVariant === 'error'}
            aria-errormessage={formVariant === 'error' ? helperText : undefined}
            style={
              selectorProps || innerText
                ? { paddingRight: selectorWidth || innerTextWidth + 12 }
                : undefined
            }
            {...rest}
          />

          {rightIcon && !selectorProps && (
            <InputIcon className={rightIcon} isRight size={sizeVariant} disabled={disabled} />
          )}

          {innerText && !rightIcon && !selectorProps && (
            <InnerText ref={innerTextRef} size={sizeVariant}>
              {innerText}
            </InnerText>
          )}

          {!!selectorProps && (
            <Selector ref={selectorRef} size={sizeVariant} inputRef={inputRef} {...selectorProps} />
          )}
        </Box>

        {helperText && (
          <HelperText descriptionId={descriptionId} variant={formVariant} mt="4">
            {helperText}
          </HelperText>
        )}
      </Wrapper>
    );
  },
);
