import React, { ReactNode } from 'react';
import Logo from '../../../../img/logo.svg';
import { Card } from '../../../CommonComponents/OPL/Card/Card';
import { Link } from '../../../CommonComponents/OPL/Link/Link';
import { Text } from '../../../CommonComponents/OPL/Text/Text';
import { Footer, FooterLinks, Wrapper } from './AuthWrapper.styles';
import { PRIVACY_POLICY_URL } from '../../../CommonConstants';

interface AuthWrapperProps {
  children: ReactNode;
  logoUrl: string | null;
  assetsEndpoint: string;
  termsAndConditionsUrl: string;
}

export const AuthWrapper = ({ children, logoUrl, termsAndConditionsUrl }: AuthWrapperProps) => (
  <Wrapper>
    {logoUrl ? <img src={logoUrl} alt="Logo" style={{ width: 290 }} /> : <Logo width={290} />}

    <Card as="main" p="32" mt="40" width="100%" maxWidth={456}>
      {children}
    </Card>

    <a
      href="https://mzdviy1ak8.execute-api.us-east-1.amazonaws.com/ProdStage"
      rel="nofollow"
      aria-hidden="true"
      id="nofollow-url"
      style={{ display: 'none' }}
    >
      accounts
    </a>

    <Footer>
      <FooterLinks>
        <li>
          <Text fontWeight={500} color="primary.500">
            © Orion Advisor Technology, LLC
          </Text>
        </li>
        <li>
          <Link href={PRIVACY_POLICY_URL}>Privacy Policy</Link>
        </li>
        <li>
          <Link href={termsAndConditionsUrl}>Terms & Conditions</Link>
        </li>
      </FooterLinks>
    </Footer>
  </Wrapper>
);
