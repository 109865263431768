import styled from 'styled-components';
import { RawButton } from '../../../../CommonComponents/OPL/Button/Button.styles';

export const MethodButton = styled(RawButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  border: `1px solid ${theme.colors.neutral['200']}`,
  borderWidth: '1px 0',
  marginTop: -1,
  borderRadius: 0,
  width: '100%',
  cursor: 'pointer',
  transition: `background ${theme.transitions.normal}, color ${theme.transitions.normal}`,

  '&:not(.disabled):not(:disabled):hover': {
    backgroundColor: theme.colors.neutral['100'],
  },
}));
