import times from 'lodash/times';
import React from 'react';
import { Box, BoxProps } from '../Box/Box';
import {
  LoadingDot,
  LoadingDotsContainer,
  LoadingSize,
  LoadingText,
  LoadingVariant,
} from './Loading.styles';

export interface Props extends BoxProps {
  variant?: LoadingVariant;
  size?: LoadingSize;
  text?: string;
}

export const Loading: React.FC<Props> = ({ variant = 'dark', size = 'medium', text, ...rest }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" {...rest}>
      <LoadingDotsContainer>
        {times(3).map(i => (
          <LoadingDot key={i} variant={variant} size={size} />
        ))}
      </LoadingDotsContainer>

      {!!text && (
        <LoadingText variant={variant} size={size}>
          {text}
        </LoadingText>
      )}
    </Box>
  );
};
