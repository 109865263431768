import React, { useState } from 'react';
import { GENERIC_ERROR, SendCodeFn, TwoFactorAuthMethod } from '../../../../Login.meta';
import { toast } from '../../../../../../CommonComponents/OPL/Toast/Toast';
import { Box } from '../../../../../../CommonComponents/OPL/Box/Box';
import { Text } from '../../../../../../CommonComponents/OPL/Text/Text';
import { Loading } from '../../../../../../CommonComponents/OPL/Loading/Loading';
import { Button } from '../../../../../../CommonComponents/OPL/Button/Button';
import { Icon } from '../../../../../../CommonComponents/OPL/Icon/Icon';

interface Props {
  method: TwoFactorAuthMethod;
  sendCode: SendCodeFn;
}

export const ResendCode = (props: Props) => {
  return (
    <Box display="flex" alignItems="center" height={20}>
      <ResendCodeContent {...props} />
    </Box>
  );
};

const ResendCodeContent = ({ method, sendCode }: Props) => {
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSendCode = async () => {
    try {
      setIsSendingCode(true);

      await sendCode({ methodId: method.id });

      setIsSuccess(true);

      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
    } catch (error) {
      console.error(error);

      toast.error(GENERIC_ERROR);
    } finally {
      setIsSendingCode(false);
    }
  };

  if (isSuccess) {
    return (
      <Box display="flex" alignItems="center">
        <Icon className="fas fa-check" color="success.600" />

        <Text fontWeight={500} color="success.600" ml="4">
          Code sent
        </Text>
      </Box>
    );
  }

  if (isSendingCode) {
    return (
      <Box display="flex" alignItems="center">
        <Loading size="small" />

        <Text fontWeight={500} color="neutral.500" ml="4">
          Resending Code
        </Text>
      </Box>
    );
  }

  return (
    <Button variant="link" onClick={handleSendCode}>
      Resend code
    </Button>
  );
};
