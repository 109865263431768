import React, { useState } from 'react';
import ReactSelect, { Theme as RSTheme } from 'react-select';
import { useTheme } from '../../../../../theme/theme';
import { customTheme, defaultStyles, SelectorWrapper } from './Selector.styles';
import { DropdownIndicator } from './components/DropdownIndicator';
import { Option } from './components/Option';
import { InputSizeVariant } from '../../Input.styles';

interface Props {
  defaultValue: any;
  options: any;
  onChange(opt: any): void;
  size: InputSizeVariant;
  inputRef: any;
  ariaLabel: string;
}

export const Selector = React.forwardRef<HTMLDivElement, Props>(
  ({ defaultValue, options, onChange, size, inputRef, ariaLabel }, ref) => {
    const theme = useTheme();

    const [option, setOption] = useState(defaultValue);

    return (
      <SelectorWrapper ref={ref} size={size}>
        <ReactSelect
          value={option}
          options={options}
          onChange={opt => {
            onChange(opt);

            setOption(opt);

            if (inputRef && inputRef.current) {
              inputRef.current.focus();
            }
          }}
          components={{ DropdownIndicator, Option }}
          isSearchable={false}
          hideSelectedOptions
          theme={(rsTheme: RSTheme) => customTheme(rsTheme, theme)}
          styles={defaultStyles(theme, size)}
          size={size}
          menuPortalTarget={document.body}
          classNamePrefix="input-selector"
          aria-label={ariaLabel}
        />
      </SelectorWrapper>
    );
  },
);
