import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

export default reducer => {
  const store = createStore(
    reducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ /* eslint-disable-line no-underscore-dangle */
        ? window.__REDUX_DEVTOOLS_EXTENSION__() /* eslint-disable-line no-underscore-dangle */
        : f => f,
    ),
  );

  window.ADV = Object.assign({}, window.ADV, { store });

  return store;
};
