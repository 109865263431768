/**
 * This Error is needed to distinguish between API 200's with errors and all other HTTP errors
 *
 * This implementation is based on this TS recommendation:
 * https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
 * In vanilla JS we can simply do "class ApiError extends Error {}"
 * However in TypeScript doing that will cause tests to fail because err
 * will not be an instance of ApiError (see authFetch catch block)
 */
export class ApiError extends Error {
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
