import React, { useCallback } from 'react';
import styled from 'styled-components';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { variant as ssVariant } from 'styled-system';
import { LayoutProps } from '../../../theme/styledProps';

export type ModalSize = 'small' | 'medium' | 'large' | 'xl';

type ModalVariant = 'modal' | 'drawer' | 'bottom';

interface CustomStylesProps {
  centered?: boolean;
  isHidden?: boolean;
}

export const customStyles = ({
  centered,
  isHidden,
}: CustomStylesProps): { overlay: React.CSSProperties } => ({
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    background: 'rgba(229, 231, 235, 0.75)',
    backdropFilter: 'blur',
    zIndex: 15000,
    display: centered ? 'flex' : 'block',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px 0',
    overflowY: centered ? 'hidden' : 'auto',
    visibility: isHidden ? 'hidden' : 'visible',
  },
});

export interface ModalProps extends ReactModalProps {
  size?: ModalSize;
  variant?: ModalVariant;
  centered?: boolean;
  /**
   * This prop is useful when we need to show a confirmation dialog
   * from within a modal. Unfortunately, we cannot use the `isOpen`
   * prop since it will unmount the parent modal.
   * https://github.com/reactjs/react-modal/issues/772
   */
  isHidden?: boolean;
  drawerPlacement?: 'left' | 'right';
}

export const StyledModal = styled(ReactModal)<ModalProps>(
  ({ theme, isHidden }) => ({
    fontFamily: '"Inter", sans-serif',
    width: '100%',
    background: theme.colors.bg,
    boxShadow: `0px 24px 50px ${theme.colors.neutral['700']}40`,
    position: 'relative',
    overflowY: 'auto',
    borderRadius: theme.radii.xl,
    padding: 0,
    outline: 'none',
    margin: '0 auto',
    visibility: isHidden ? 'hidden' : 'visible',
  }),

  ssVariant<LayoutProps, ModalSize>({
    prop: 'size',
    variants: {
      small: {
        maxWidth: '400px',
      },
      medium: {
        maxWidth: '600px',
      },
      large: {
        maxWidth: '800px',
      },
      xl: {
        maxWidth: '976px',
      },
    },
  }),
);

const CenteredModal = styled(StyledModal)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  overflow: 'revert',
});

interface DrawerProps {
  drawerPlacement: Pick<ModalProps, 'drawerPlacement'>;
}

const Drawer = styled(StyledModal)<DrawerProps>(({ drawerPlacement }) => ({
  top: 0,
  left: drawerPlacement === 'left' ? 0 : 'unset',
  right: drawerPlacement === 'right' ? 0 : 'unset',
  width: 448,
  bottom: 0,
  borderRadius: 'revert',
  position: 'absolute',
  overflowY: 'revert',
  display: 'flex',
  flexDirection: 'column',
}));

export const bottomStyles = ({
  isHidden,
}: Omit<CustomStylesProps, 'centered'>): {
  overlay: React.CSSProperties;
  content: React.CSSProperties;
} => ({
  overlay: {
    background: 'rgba(229, 231, 235, 0.75)',
    backdropFilter: 'blur(4px)',
    zIndex: 15000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px 0 0 0',
    visibility: isHidden ? 'hidden' : 'visible',
  },
  content: {
    position: 'fixed',
    top: 'auto',
    left: '0',
    bottom: '0',
    right: '0',
    zIndex: 15001,
  },
});

export const Modal: React.FC<ModalProps> = ({
  size = 'medium',
  centered = true,
  variant = 'modal',
  drawerPlacement = 'left',
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const parentSelector = useCallback(() => document.getElementById('modals')!, []);

  const Component = variant === 'drawer' ? Drawer : centered ? CenteredModal : StyledModal;

  return (
    <Component
      size={size}
      centered={centered}
      drawerPlacement={drawerPlacement}
      parentSelector={parentSelector}
      style={variant === 'bottom' ? bottomStyles(rest) : customStyles({ centered, ...rest })}
      {...rest}
    />
  );
};
