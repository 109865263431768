import styled from 'styled-components';

export const ModalBody = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral['500']};
  line-height: 20px;
  padding: 2px 24px 24px;
  overflow-y: auto;
`;
