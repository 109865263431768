import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../../../../Box/Box';
import { Text } from '../../../../Text/Text';

interface OptionWrapperProps extends BoxProps {
  isFocused: boolean;
}

const OptionWrapper = styled(Box)<OptionWrapperProps>(({ isFocused, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  fontWeight: 500,
  color: theme.colors.neutral['700'],
  background: isFocused ? theme.colors.neutral['100'] : theme.colors.bg,
  height: 'auto',
}));

export const Option: React.FC<any> = ({ innerProps, isFocused, innerRef, data, selectProps }) => {
  const { label } = data;
  const { size } = selectProps;

  return (
    <OptionWrapper height="32px" p="8" isFocused={isFocused} ref={innerRef} {...innerProps}>
      <Text fontWeight={500} fontSize={size === 'small' ? 'small' : 'body'}>
        {label}
      </Text>
    </OptionWrapper>
  );
};
