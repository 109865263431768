import { createSelector } from 'reselect';
import { PdfReducer } from './PDFRendererTypes';

const selectPdfState = (state: { pdfReducer: PdfReducer }) => state.pdfReducer;

export const selectLoadedComponents = createSelector(
  selectPdfState,
  ({ loadedComponents }) => loadedComponents,
);

export const selectLoadedAdvancedReportsSections = createSelector(
  selectPdfState,
  ({ loadedAdvancedReportsSections }) => loadedAdvancedReportsSections,
);

const selectClient = createSelector(selectPdfState, ({ client }) => client);

export const selectHasDocumentVaultAccess = createSelector(
  selectClient,
  ({ ModulesToHide: modulesToHide }) => !(modulesToHide || []).includes('Document Vault'),
);

export const selectFeatureFlags = createSelector(
  selectPdfState,
  ({ featureFlags }) => featureFlags,
);
