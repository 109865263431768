export enum RiskToleranceQuestionnaireType {
  Advizr,
  HiddenLevers,
}

export enum OnboardingWorkflow {
  None = -1,
  Comprehensive,
  ManagedAccounts,
  RetirementWorkflow,
  EducationWorkflow,
  HomePurchaseWorkflow,
  DebtManagementWorkflow,
  AllWorkflows,
  LargePurchaseWorkflow,
  EmergencyFundWorkflow,
  AccountsWorkflow,
  WellnessWorkflow,
  HealthCareWorkflow,
  RiskToleranceWorkflow,
  AutoAccountWorkflow,
  AssetBucketingWorkflow,
  BeFi20Workflow,
  EstatePlanning,
  Decumulation,
  PulseCheckWorkflow,
}

export interface FeatureFlag {
  name: Feature;
  enabled: boolean;
}

export enum Feature {
  NewWhatIfs = 'NewWhatIfs',
  AssetBucketingTab = 'AssetBucketingTab',
  LocalizeAccess = 'LocalizeAccess',
  EnableAssetBucketingWhatIfs = 'EnableAssetBucketingWhatIfs',
  HiddenLeversRTQWebComponent = 'HiddenLeversRTQWebComponent',
  CanadianProvinces = 'CanadianProvinces',
  BeFi20 = 'BeFi20',
  EnableEstatePlanning = 'EnableEstatePlanning',
  PulseCheck = 'PulseCheck',
  RedtailImaging = 'RedtailImaging',
  EnableHideAutomaticAccountUpdatesToggle = 'EnableHideAutomaticAccountUpdatesToggle',
  DecumulationProposalAccountWf = 'DecumulationProposalAccountWf',
}

export enum UserRole {
  Guest,
  Client,
  Advisor,
  Administrator,
  EmployerRepresentative,
}

// only need to update the enums below when changing for Localize language options
export enum Language {
  EnglishAmerican,
  FrenchCanadian,
  EnglishCanadian,
  SpanishLatinAmerican,
}

export enum LanguageCode {
  EnglishAmerican = 'en',
  FrenchCanadian = 'fr-CA',
  EnglishCanadian = 'en-CA',
  SpanishLatinAmerican = 'es-LA',
}

export interface ExternalAccountProviderAccessDTO {
  id: string;
  imageUrl: string | null;
  name: string;
  url: string;
  website: string | null;
}

export interface CurrentClientDTO {
  Id: number;
  Username: string;
  FirstName: string;
  LastName: string;
  Email: string;
  PartnerName: string | null;
  IsRegistered: boolean;
  IsPlanGenerated: boolean;
  ClientAccess: ClientAccessDTO;
  ConsumerAccessWorkflow: OnboardingWorkflow;
  ComponentName: string | null;
  ComponentProps: Record<string, any> | null;
  ModulesToHide: string[] | null;
  OptOuts: string[] | null;
  AccountsGrouping: number;
  CanDeleteConsumer: boolean;
  IsOrionClient: boolean;
  CanPreviewClient: boolean;
  HasMoneyGuideProAccess: boolean;
  HasEMoneyAccess: boolean;
  HasRISAAccess: boolean;
  HasRightCapitalAccess: boolean;
  ShowFinancialPlanning: boolean;
  HasOrionTradingAccess: boolean;
  HasReportsAccess: boolean;
  ShowNewsFeed: boolean;
  HasOrionRiskAlyseIntegration: boolean;
  HasDataReconciliationAccess: boolean;
  HasRMD: boolean;
  ShowApexAcatsTransferFlow: boolean;
  HasEStatementSettings: boolean;
  IsCanadian: boolean;
  PartnerDob: string;
  Dob: string;
  SocialSecurityUseSpousalBenefit: boolean;
  ExternalAccountProviderAccess: ExternalAccountProviderAccessDTO[];
  OrionWidgetsChartType: ChartType;
  HasMobileNavigation: boolean;
  ShowIncomeTab: boolean;
}

export interface ClientAccessDTO {
  CurrentOnboardingWorkflow: number | null;
  OnboardingWorkflowOptions: OptionDTO[];
  DashboardAccessSet: number[];
  LinkAccountsAccess: boolean;
  CreditMonitoringAccess: boolean;
  DataReconciliationAccess: boolean;
  LastCompletedOnboardingMessage: string;
  ClientLandingPage: number;
  DefaultClientLandingPage: number;
  ShowProBono: boolean;
  IsProBonoClient: boolean;
  TaxableAccountModelGroup: TaxableAccountModelGroupDTO | null;
  NonTaxableAccountModelGroup: TaxableAccountModelGroupDTO | null;
  FullyAutomatedAccountOpening: boolean;
  DisplayAssetBucketing: boolean;
  RiskToleranceQuestionnaire: number;
  ClientLandingPageOptions: OptionDTO[];
  SelectedAgreements: any[];
  AgreementOptions: AgreementOptionDTO[];
  IsPreviewLinkEnabled: boolean;
  HiddenLeversRtqWebComponent: boolean;
}

interface TaxableAccountModelGroupDTO {
  groupId: string;
  groupName: string;
  externalSystem: number;
  adviserId: number;
  modelPortfolios: ModelPortfolioDTO[];
}

export interface ModelPortfolioDTO {
  Id: string;
  PortfolioName: string;
  PortfolioDescription: string;
  AssetAllocationInputModel: AssetAllocationInputModelDTO[];
  HypotheticalReturn: number;
  RiskScore: number | null;
  OrionModelId: number;
  RiskRange: RiskRangeDTO;
}

export interface AssetAllocationInputModelDTO {
  id: number;
  name: string | null;
  y: number;
  color_group: number;
  color: string | null;
}

export interface RiskRangeDTO {
  From: number;
  To: number;
}

export interface AgreementOptionDTO {
  Value: string;
  Label: string;
}

export interface OptionDTO {
  label: string;
  value: number;
}

export interface FooterDisclosures {
  Body: string;
  Header: string;
}

export enum ShowRelatedHouseholdsOption {
  Hide,
  ShowButDoNotIncludeInAllAccounts,
  ShowAndIncludeInAllAccounts,
}

export enum ChartType {
  Bar,
  Pie,
  Donut,
}
