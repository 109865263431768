import React from 'react';
import { cssTransition, toast as rtToast, ToastContainerProps, ToastOptions } from 'react-toastify';
import { StyledToastContainer, ToastTitle, ToastVariant, ToastWrapper } from './Toast.styles';

const slide = cssTransition({
  enter: 'slide-top',
  exit: 'slide-bottom',
});

const ToastContainer: React.FC<ToastContainerProps> = props => {
  return (
    <StyledToastContainer
      position="bottom-right"
      autoClose={5000}
      transition={slide}
      hideProgressBar
      {...props}
    />
  );
};

interface ToastProps {
  content: string;
  variant?: ToastVariant;
}

const Toast: React.FC<ToastProps> = ({ content, variant = 'default' }) => {
  return (
    <ToastWrapper>
      <ToastTitle variant={variant}>{content}</ToastTitle>
    </ToastWrapper>
  );
};

const toast = (content: string, options?: ToastOptions) =>
  rtToast(<Toast content={content} />, options);

const createToast = (variant: ToastVariant) => {
  return (content: string, options?: ToastOptions) =>
    rtToast(<Toast variant={variant} content={content} />, options);
};

toast.default = createToast('default');
toast.info = createToast('info');
toast.success = createToast('success');
toast.warning = createToast('warning');
toast.error = createToast('error');
toast.progress = createToast('progress');

const extendedToast = Object.assign(rtToast, toast);

export { ToastContainer, extendedToast as toast };
