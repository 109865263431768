import { createSelector } from 'reselect';
import { ChartType, RiskToleranceQuestionnaireType } from '../CommonSelectors/CommonArea.types';
import {
  Adviser,
  CurrentClientDTO,
  DashboardAccessWorkflowPresets,
  DashboardFeatureId,
} from './AdviserArea.types';

const CLIENTS_LIST = 'ClientsList';
const NEW_CLIENT = 'NewClient';
export const DASHBOARD = 'Dashboard';

export const adviserArea = state => state.AdviserArea;

export const areApplicationVariablesLoadedSelector = createSelector(
  adviserArea,
  ({ areApplicationVariablesLoaded }) => areApplicationVariablesLoaded,
);

export const currentClientSelector = createSelector(
  adviserArea,
  ({ currentClient }): CurrentClientDTO | null => currentClient,
);

export const showFinancialPlanningSelector = createSelector(
  adviserArea,
  ({ showFinancialPlanning }): boolean => showFinancialPlanning,
);

export const dashboardAccessOptionsSelector = createSelector(
  adviserArea,
  currentClientSelector,
  ({ dashboardAccessOptions, showFinancialPlanning }, currentClient) => {
    // This filter will be done on BE.
    // FE will do it in the mean time, since it is simpler than BE and is time sensitive.
    // https://dev.azure.com/orionadvisor/Orion%20Agile/_workitems/edit/231337
    //
    const financialPlanOptions = [
      DashboardFeatureId.FinancialPlan,
      DashboardFeatureId.WhatIfs,
      DashboardFeatureId.EditProfile,
    ];

    return dashboardAccessOptions.filter(option => {
      if (financialPlanOptions.includes(option.value)) {
        return showFinancialPlanning;
      }

      if (option.value === DashboardFeatureId.Newsfeed) {
        return currentClient && currentClient.IsOrionClient;
      }

      if (option.value === DashboardFeatureId.ContactAdviser) {
        const ALWAYS_SHOW_ON_CAMPAIGNS = true;

        return currentClient ? !currentClient.IsOrionClient : ALWAYS_SHOW_ON_CAMPAIGNS;
      }

      return true;
    });
  },
);

export const defaultDashboardAccessSelector = createSelector(
  adviserArea,
  ({ defaultDashboardAccess }): DashboardFeatureId[] => defaultDashboardAccess,
);

export const dashboardAccessWorkflowPresetsSelector = createSelector(
  adviserArea,
  ({ dashboardAccessWorkflowPresets }): DashboardAccessWorkflowPresets =>
    dashboardAccessWorkflowPresets,
);

export const currentClientIdSelector = createSelector(
  adviserArea,
  ({ currentClient }) => currentClient && currentClient.Id,
);

export const shouldShowStartPageSelector = createSelector(
  adviserArea,
  ({ showStartPage }): boolean => showStartPage,
);

export const isDemoSelector = createSelector(adviserArea, ({ isDemo }) => isDemo);

export const isFetchingCurrentClientSelector = createSelector(
  adviserArea,
  ({ isFetchingCurrentClient }) => isFetchingCurrentClient,
);

export const demoClientInstanceIdSelector = createSelector(
  adviserArea,
  ({ demoClientInstanceId }) => demoClientInstanceId,
);

export const demoClientIdSelector = createSelector(adviserArea, ({ demoClientId }) => demoClientId);

export const modulesToHideSelector = createSelector(
  adviserArea,
  ({ modulesToHide }) => modulesToHide,
);

export const isDocumentVaultVisibleSelector = createSelector(
  modulesToHideSelector,
  modulesToHide => modulesToHide.indexOf('Document Vault') < 0,
);

export const homeIsSearchClients = state => adviserArea(state).homeIsSearchClients;

export const optsOut = state => adviserArea(state).optsOut;

export const showDashboard = (state): boolean => optsOut(state).indexOf(DASHBOARD) < 0;

export const showClientsList = (state): boolean => optsOut(state).indexOf(CLIENTS_LIST) < 0;

export const showAddClient = (state): boolean => optsOut(state).indexOf(NEW_CLIENT) < 0;

export const accountsGroupingType = state => adviserArea(state).accountsGrouping;

export const currentPage = state => adviserArea(state).current_page;

export const isMultipleSessionWarningModalVisible = state =>
  adviserArea(state).isMultipleSessionWarningModalVisible;

export const showLeads = (state): boolean => adviserArea(state).showLeads;

export const showManagedAccountsSelector = createSelector(
  adviserArea,
  ({ showManagedAccounts }) => showManagedAccounts,
);

export const hasDataAggregationSelector = createSelector(
  adviserArea,
  ({ hasDataAggregation }): boolean => hasDataAggregation,
);

export const hasCreditMonitoringSelector = createSelector(
  adviserArea,
  ({ hasCreditMonitoring }): boolean => hasCreditMonitoring,
);

export const canInviteConsumerSelector = createSelector(
  adviserArea,
  ({ canInviteConsumer }) => canInviteConsumer,
);

export const whiteLabelDashboardLogoCssStyleSelector = createSelector(
  adviserArea,
  ({ whiteLabelDashboardLogoCssStyle }) => whiteLabelDashboardLogoCssStyle,
);

export const sampleClientsSelector = createSelector(
  adviserArea,
  ({ sample_clients: sampleClients }) => sampleClients,
);

export const showStartPageSelector = createSelector(
  adviserArea,
  ({ showStartPage }) => showStartPage,
);

export const showAssetClassMappingSelector = createSelector(
  adviserArea,
  ({ showAssetClassMapping }) => showAssetClassMapping,
);

export const adviserFirstNameSelector = createSelector(
  adviserArea,
  ({ first_name: firstName }): string => firstName,
);

export const showRiskToleranceWorkflowSelector = createSelector(
  adviserArea,
  ({ showRiskToleranceWorkflow }) => showRiskToleranceWorkflow,
);

export const showCampaignsSelector = createSelector(
  adviserArea,
  ({ showCampaigns }): boolean => showCampaigns,
);

export const firmSettingsAccessSelector = createSelector(
  adviserArea,
  ({ firmSettingsAccess }) => firmSettingsAccess,
);

export const searchInputPlaceholderSelector = createSelector(
  adviserArea,
  ({ searchInputPlaceholder }): string => searchInputPlaceholder,
);

export const selectIsOrionAdviser = createSelector(adviserArea, ({ isOrion }) => isOrion);

export const selectHasGliaCobrowse = createSelector(
  adviserArea,
  ({ showGliaCobrowse }) => showGliaCobrowse,
);

export const adviserSelector = createSelector(adviserArea, ({ adviser }): Adviser => adviser);

export const selectCurrentAdviserId = createSelector(adviserSelector, ({ id }) => id);

export const showMgpIntegrationSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasMoneyGuideProAccess,
);

export const isDocumentVaultReadonlySelector = createSelector(
  adviserArea,
  ({ isDocumentVaultReadonly }) => isDocumentVaultReadonly,
);

export const showEMoneyIntegrationSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasEMoneyAccess,
);

export const showSchwabIntegrationSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasSchwabAccess,
);

export const showRISAIntegrationSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasRISAAccess,
);

export const showOrionTradingSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasOrionTradingAccess,
);

export const isDisableAdviserSuggestionsSelector = createSelector(
  adviserArea,
  ({ disableAdviserSuggestions }): boolean => disableAdviserSuggestions,
);

export const hasPlaidSelector = createSelector(adviserArea, ({ hasPlaid }) => hasPlaid);

export const selectHasAkoya = createSelector(adviserArea, ({ hasAkoya }) => hasAkoya);

export const hasDataReconciliationSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasDataReconciliationAccess,
);

export const hasAdviserDataReconciliationSelector = createSelector(
  adviserArea,
  ({ hasDataReconciliation }) => hasDataReconciliation,
);

export const showPortfolioReportsSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasReportsAccess,
);

export const showTransactionsPageSelector = createSelector(
  adviserArea,
  ({ showTransactionsPage }) => showTransactionsPage,
);

export const selectShowExecutiveSummary = createSelector(
  adviserArea,
  ({ showExecutiveSummary }) => showExecutiveSummary,
);

export const selectShowApexAcatsTransferFlow = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.ShowApexAcatsTransferFlow,
);

export const hasEStatementSettingsSelector = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasEStatementSettings,
);

export const selectFeatureFlags = createSelector(adviserArea, ({ featureFlags }) => featureFlags);

export const selectOrgLogo = createSelector(
  adviserArea,
  ({ whiteLabelLogoUrl }) => whiteLabelLogoUrl,
);

export const selectRiskToleranceQuestionnaire = createSelector(
  currentClientSelector,
  currentClient =>
    currentClient?.ClientAccess?.RiskToleranceQuestionnaire ||
    RiskToleranceQuestionnaireType.Advizr,
);

export const selectHasAssetBucketing = createSelector(adviserArea, ({ hasAssetBucketing }) => {
  return hasAssetBucketing;
});

export const selectHasAssetBucketingFirm = createSelector(
  adviserArea,
  ({ hasAssetBucketingFirm }) => {
    return hasAssetBucketingFirm;
  },
);

export const selectAssetBucketingHeadline = createSelector(
  adviserArea,
  ({ assetBucketingHeadline }): string => {
    return assetBucketingHeadline;
  },
);

export const selectPortalName = createSelector(
  adviserArea,
  ({ portalName }: { portalName: string }) => portalName,
);

export const selectHasRightCapitalAccess = createSelector(
  currentClientSelector,
  selectFeatureFlags,
  (currentClient, featureFlags) => {
    const featureFlag = featureFlags.find(flag => flag.name === 'RightCapitalSSO');

    if (featureFlag && featureFlag.enabled === false) {
      return false;
    }

    return !!currentClient?.HasRightCapitalAccess;
  },
);

export const selectFooterDisclosures = createSelector(
  adviserArea,
  ({ footerDisclosures }) => footerDisclosures,
);

export const selectHasBeFi20 = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasBeFi20,
);

export const selectHasPulseCheck = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.HasPulseCheck,
);

export const selectShowAlternativeDetail = createSelector(
  currentClientSelector,
  currentClient => !!currentClient?.ShowAlternativeDetail,
);

export const showBrinkerMessageSelector = createSelector(
  adviserArea,
  ({ showBrinkerMessage }): boolean => showBrinkerMessage,
);

export const selectHasAdvisorAssetBucketingLayoutAccess = createSelector(
  adviserArea,
  ({ hasAdvisorAssetBucketingLayoutAccess }): string => {
    return hasAdvisorAssetBucketingLayoutAccess;
  },
);

export const selectOrionWidgetsChartType = createSelector(
  currentClientSelector,
  currentClient => currentClient?.OrionWidgetsChartType || ChartType.Bar,
);
