import styled from 'styled-components';
import {
  compose,
  flexbox,
  FlexboxProps,
  layout,
  position,
  PositionProps,
  space,
  color,
  border,
  background,
  BackgroundProps,
  overflow,
  OverflowProps,
  gridGap,
  GridGapProps,
  boxShadow,
} from 'styled-system';
import {
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
  BoxShadowProps,
} from '../../../theme/styledProps';

export interface BoxProps
  extends SpaceProps,
    FlexboxProps,
    PositionProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    BackgroundProps,
    OverflowProps,
    GridGapProps,
    BoxShadowProps {}

export const Box = styled.div<BoxProps>(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },

  compose(
    space,
    flexbox,
    position,
    layout,
    color,
    border,
    background,
    overflow,
    gridGap,
    boxShadow,
  ),
);
