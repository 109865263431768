import React from 'react';
import { StyledIconButton } from './IconButton.styles';
import { MarginProps } from '../../../theme/styledProps';
import { ButtonSize, ButtonVariant, ButtonShape } from '../Button/Button.styles';

export interface Props extends React.ComponentPropsWithoutRef<'button'>, MarginProps {
  icon: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  showCaret?: boolean;
  isLoading?: boolean;
  shape?: ButtonShape;
}

/**
 * IconButton composes the Button component. It renders only an icon.
 */
export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      icon,
      variant = 'primary',
      size = 'medium',
      shape = 'square',
      // Filter children we do not want it on ...rest
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      children,
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledIconButton
        variant={variant}
        size={size}
        leftIcon={icon}
        ref={ref}
        isIconButton
        shape={shape}
        {...rest}
      />
    );
  },
);
