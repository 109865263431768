import React, { useEffect, useState } from 'react';
import { ModalHeader } from '../../../../CommonComponents/OPL/Modal/ModalHeader';
import { ModalBody } from '../../../../CommonComponents/OPL/Modal/ModalBody';
import { Modal } from '../../../../CommonComponents/OPL/Modal/Modal';
import { Box } from '../../../../CommonComponents/OPL/Box/Box';
import { Button } from '../../../../CommonComponents/OPL/Button/Button';
import { ModalFooter } from '../../../../CommonComponents/OPL/Modal/ModalFooter';
import { Text } from '../../../../CommonComponents/OPL/Text/Text';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const appElement = document.getElementById('app')!;

interface Props {
  url: string;
  message: string;
}

export const RedirectModal = ({ url, message }: Props) => {
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(secondsState => secondsState - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (seconds === 0) {
    window.location.replace(url);
  }

  return (
    <>
      <Modal isOpen appElement={appElement}>
        <ModalHeader variant="warning">Authentication</ModalHeader>

        <ModalBody css="height: 200px">
          <Box display="flex" justifyContent="center" height="100%" flexDirection="column">
            <Text>{message}</Text>

            <Text>You will be redirected in {seconds}s</Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={() => window.location.replace(url)}>Redirect now</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
