import styled from 'styled-components';
import { variant, WidthProps } from 'styled-system';
import { Text } from '../Text/Text';
import { ColorProps, PropsWithPseudo } from '../../../theme/styledProps';

export type HelperTextVariant = 'success' | 'warning' | 'error';

type Pseudo = '&::before';

export interface StyledHelperTextProps {
  variant?: HelperTextVariant;
}

export const StyledHelperText = styled(Text)<StyledHelperTextProps>(
  ({ theme }) => ({
    fontSize: theme.fontSizes.small,
    lineHeight: theme.lineHeights.small,
    color: theme.colors.neutral['500'],
    display: 'flex',
    alignItems: 'center',

    '&::before': {
      fontFamily: '"Font Awesome 5 Pro"',
      fontSize: 12,
      fontWeight: 700,
      marginRight: 4,
    },
  }),

  variant<
    PropsWithPseudo<ColorProps & WidthProps & { content?: string }, Pseudo>,
    HelperTextVariant
  >({
    variants: {
      success: {
        color: 'success.700',

        '&::before': {
          content: "'\f00c'",
          color: 'success.600',
        },
      },

      warning: {
        color: 'neutral.500',

        '&::before': {
          content: "'\f071'",
          color: 'warning.400',
        },
      },

      error: {
        color: 'error.700',

        '&::before': {
          content: "'\f071'",
          color: 'error.600',
        },
      },
    },
  }),
);
