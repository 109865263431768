import { StylesConfig as ReactSelectStyles, Theme as RSTheme } from 'react-select';
import styled from 'styled-components';
import { PositionProps, variant } from 'styled-system';
import { Theme } from '../../../../../theme/theme';
import { InputSizeVariant } from '../../Input.styles';

export const customTheme: (rsTheme: RSTheme, theme: Theme) => RSTheme = (rsTheme, theme) => ({
  ...rsTheme,

  spacing: {
    ...rsTheme.spacing,

    controlHeight: 20,
    baseUnit: 2,
  },

  colors: {
    ...rsTheme.colors,
    primary: theme.colors.primary['600'],
    primary75: theme.colors.primary['300'],
    primary50: theme.colors.primary['100'],
    primary25: theme.colors.primary['50'],

    danger: theme.colors.error['600'],
    dangerLight: theme.colors.error['100'],

    neutral0: theme.colors.bg,
    neutral5: theme.colors.neutral['50'],
    neutral10: theme.colors.neutral['100'],
    neutral20: theme.colors.neutral['200'],
    neutral30: theme.colors.neutral['300'],
    neutral40: theme.colors.neutral['400'],
    neutral50: theme.colors.neutral['500'],
    neutral60: theme.colors.neutral['600'],
    neutral70: theme.colors.neutral['700'],
    neutral80: theme.colors.neutral['800'],
    neutral90: theme.colors.neutral['900'],
  },
});

export const defaultStyles = (
  theme: Theme,
  size?: InputSizeVariant,
): Partial<ReactSelectStyles<any, any>> => ({
  container: provided => {
    return {
      ...provided,
      width: '100%',
      fontFamily: theme.fontFamily,
      fontSize: size === 'small' ? theme.fontSizes.small : theme.fontSizes.body,
    };
  },

  control: (provided, state) => {
    return {
      ...provided,
      borderWidth: 0,
      boxShadow: 'none',
      minHeight: 'auto',
      backgroundColor: state.isDisabled ? theme.colors.neutral['100'] : theme.colors.bg,
      transition: 'none',
      textAlign: 'right',
      cursor: 'pointer',
    };
  },

  valueContainer: provided => {
    return {
      ...provided,
      justifyContent: 'flex-end',
    };
  },

  singleValue: provided => {
    return {
      ...provided,
      color: theme.colors.neutral['400'],
      transform: 'none',
      position: 'relative',
      maxWidth: 'unset',
      fontFamily: theme.fontFamily,
    };
  },

  menu: provided => ({
    ...provided,
    fontSize: size === 'small' ? theme.fontSizes.small : theme.fontSizes.body,
    marginTop: 4,
    border: `1px solid ${theme.colors.neutral['200']}`,
    boxShadow: theme.shadows['500'],
    borderRadius: 4,
    zIndex: 20000,
    paddingTop: size === 'small' ? 2 : 6,
    paddingBottom: size === 'small' ? 2 : 6,
    right: -8,
    whiteSpace: 'nowrap',
    width: 'auto',
    minWidth: '100%',
  }),

  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  placeholder: provided => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  }),

  menuPortal: base => ({
    ...base,
    zIndex: 16000,
  }),
});

export const SelectorWrapper = styled.div<{ size: InputSizeVariant }>(
  ({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    right: 8,

    '[data-whatinput="keyboard"] & .input-selector__control--is-focused': {
      boxShadow: `0 0 0 2px ${theme.colors.primary['300']}, inset 0 0 0 1px ${theme.colors.primary['500']}`,
    },
  }),

  variant<PositionProps, InputSizeVariant>({
    prop: 'size',
    variants: {
      small: {
        top: 6,
      },
      medium: {
        top: 8,
      },
      large: {
        top: 10,
      },
    },
  }),
);
