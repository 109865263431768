import styled, { keyframes } from 'styled-components';
import { variant } from 'styled-system';
import {
  ColorProps,
  LayoutProps,
  MarginProps,
  SpaceProps,
  TypographyProps,
} from '../../../theme/styledProps';
import { Box } from '../Box/Box';

export type LoadingVariant = 'dark' | 'light' | 'error';
export type LoadingSize = 'small' | 'medium' | 'large';

const loadingFade = (t: string) => keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(${t});
  }
  100% {
    opacity: 0;
  }
`;

interface LoadingDotProps {
  variant: LoadingVariant;
  size: LoadingSize;
}

export const LoadingDot = styled.span<LoadingDotProps>`
  float: left;
  border-radius: 50%;
  opacity: 0;
  animation: ${({ size }) =>
      loadingFade((size === 'small' && '4px') || (size === 'medium' && '8px') || '12px')}
    1.5s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  ${variant<ColorProps, LoadingVariant>({
    variants: {
      light: {
        bg: 'bg',
      },
      dark: {
        bg: 'neutral.600',
      },
      error: {
        bg: 'error.600',
      },
    },
  })}

  ${variant<LayoutProps & MarginProps, LoadingSize>({
    prop: 'size',
    variants: {
      small: {
        width: 4,
        height: 4,
        mx: 2,
        mb: 4,
      },
      medium: {
        width: 8,
        height: 8,
        mx: 3,
        mb: 8,
      },
      large: {
        width: 12,
        height: 12,
        mx: 4,
        mb: 12,
      },
    },
  })}
`;

export const LoadingDotsContainer = styled(Box)`
  overflow: auto;
  display: inline-flex;
`;

interface LoadingTextProps {
  variant: LoadingVariant;
  size: LoadingSize;
}

export const LoadingText = styled.div<LoadingTextProps>(
  ({ theme }) => ({
    fontFamily: theme.fontFamily,
    textTransform: 'uppercase',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '20px',
  }),

  variant<ColorProps, LoadingVariant>({
    variants: {
      light: {
        color: 'bg',
      },
      dark: {
        color: 'neutral.400',
      },
      error: {
        color: 'error.600',
      },
    },
  }),

  variant<TypographyProps & SpaceProps, LoadingSize>({
    prop: 'size',
    variants: {
      small: {
        fontSize: 'small',
        mt: '4',
      },
      medium: {
        fontSize: 'body',
        mt: '8',
      },
      large: {
        fontSize: 'h6',
        mt: '12',
      },
    },
  }),
);
