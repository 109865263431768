import { createSelector } from 'reselect';
import { LanguageCode, ShowRelatedHouseholdsOption } from '../CommonSelectors/CommonArea.types';

export const consumerArea = state => state.ConsumerArea;

export const selectFeatureFlags = createSelector(consumerArea, ({ featureFlags }) => featureFlags);

export const accountsGroupingType = createSelector(
  consumerArea,
  ({ accountsGrouping }) => accountsGrouping,
);

// eslint-disable-next-line @typescript-eslint/camelcase
export const currentPage = createSelector(consumerArea, ({ current_page }) => current_page);

export const clientSelector = createSelector(consumerArea, ({ client }) => client);

export const fpIdSelector = createSelector(consumerArea, ({ fpId }) => fpId);

export const isConsumerArea = state => {
  if (consumerArea(state)) {
    return consumerArea(state).isConsumerArea;
  }
  return !!consumerArea(state);
};

export const consumerApplicationVariablesLoaded = state =>
  consumerArea(state).applicationVariablesLoaded;

export const consumerOptOuts = createSelector(
  consumerArea,
  ({
    applicationVariables: {
      ConsumerAreaContext: { OptOuts },
    },
  }) => OptOuts,
);

export const isFinancialPlanEnabledSelector = createSelector(
  consumerArea,
  ({
    applicationVariables: {
      ConsumerAreaContext: { ModulesToHide },
    },
  }) => !ModulesToHide.some(mod => mod === 'FinancialPlan'),
);

export const isPersonalFinancesEnabledSelector = createSelector(
  consumerArea,
  ({
    applicationVariables: {
      ConsumerAreaContext: { ModulesToHide },
    },
  }) => !ModulesToHide.some(mod => mod === 'CurrentFinances'),
);

export const selectIsProfileEnable = createSelector(
  consumerArea,
  ({
    applicationVariables: {
      ConsumerAreaContext: { ModulesToHide },
    },
  }) => !ModulesToHide.some(mod => mod === 'Profile'),
);

export const hasClientAccessSelector = createSelector(
  consumerArea,
  ({
    applicationVariables: {
      ConsumerAreaContext: { ComponentName },
    },
  }) => ComponentName !== 'NoClientAccess',
);

export const isContactAdviserOptOut = state =>
  consumerOptOuts(state).indexOf('ContactAdviser') >= 0;

export const showConsumerContactFinancialAdviser = state =>
  isConsumerArea(state) &&
  consumerApplicationVariablesLoaded(state) &&
  !isContactAdviserOptOut(state) &&
  ADV.info &&
  !ADV.info.isPresentationMode;

export const consumerAccessWorkflowSelector = createSelector(
  consumerArea,
  ({ consumerAccessWorkflow }) => consumerAccessWorkflow,
);

export const hadAllWorkflowsInOnboardingSelector = createSelector(
  consumerArea,
  ({ hadAllWorkflowsInOnboarding }) => hadAllWorkflowsInOnboarding,
);

export const hasDataAggregationSelector = createSelector(
  consumerArea,
  ({ hasDataAggregation }) => hasDataAggregation,
);

export const showManagedAccountsSelector = createSelector(
  consumerArea,
  ({ showManagedAccounts }) => showManagedAccounts,
);

export const hasCreditMonitoringSelector = createSelector(
  consumerOptOuts,
  optOuts => optOuts.indexOf('CreditMonitoring') < 0,
);

export const showTransactionsSelector = createSelector(
  consumerOptOuts,
  optOuts => !optOuts.includes('Transactions'),
);

export const showNetWorthSelector = createSelector(
  consumerOptOuts,
  optOuts => !optOuts.includes('NetWorth'),
);

export const showDocumentVaultSelector = createSelector(
  clientSelector,
  ({ ModulesToHide }) => !ModulesToHide.includes('Document Vault'),
);

export const showMgpIntegrationSelector = createSelector(
  consumerArea,
  ({ hasMgpAccess }) => hasMgpAccess,
);

export const isDocumentVaultReadonlySelector = createSelector(
  consumerArea,
  ({ isDocumentVaultReadonly }) => isDocumentVaultReadonly,
);

export const showEMoneyIntegrationSelector = createSelector(
  consumerArea,
  ({ hasEMoneyAccess }) => hasEMoneyAccess,
);

export const showSchwabIntegrationSelector = createSelector(
  consumerArea,
  ({ hasSchwabAccess }) => hasSchwabAccess,
);

export const selectHasRightCapitalAccess = createSelector(
  consumerArea,
  selectFeatureFlags,
  ({ hasRightCapitalAccess }, featureFlags) => {
    const featureFlag = featureFlags.find(flag => flag.name === 'RightCapitalSSO');

    if (featureFlag && featureFlag.enabled === false) {
      return false;
    }

    return hasRightCapitalAccess;
  },
);

export const showRISAIntegrationSelector = createSelector(
  consumerArea,
  ({ hasRISAAccess }) => hasRISAAccess,
);

export const showOrionTradingSelector = createSelector(
  consumerArea,
  ({ hasOrionTradingAccess }) => hasOrionTradingAccess,
);

export const hasPlaidSelector = createSelector(consumerArea, ({ hasPlaid }) => hasPlaid);

export const selectHasAkoya = createSelector(consumerArea, ({ hasAkoya }) => hasAkoya);

export const hasDataReconciliationSelector = createSelector(
  consumerOptOuts,
  optOuts => !optOuts.includes('DataReconciliation'),
);

export const showPortfolioReportsSelector = createSelector(
  consumerArea,
  ({ showPortfolioReports }) => showPortfolioReports,
);

// Reports are accessible if the user has access to the financial plan
// or to portfolio reports
export const showReportsSelector = createSelector(
  isFinancialPlanEnabledSelector,
  showPortfolioReportsSelector,
  (isFinancialPlanEnabled, showPortfolioReports) => {
    return isFinancialPlanEnabled || showPortfolioReports;
  },
);

export const externalHostSelector = createSelector(
  isConsumerArea,
  consumerArea,
  (isConsumer, consumerData) => {
    if (!isConsumer) {
      return null;
    }

    return consumerData.externalHost;
  },
);

export const selectHasAssetBucketing = createSelector(
  consumerArea,
  ({ hasAssetBucketing }) => hasAssetBucketing,
);

export const showTransactionsPageSelector = createSelector(
  consumerArea,
  ({ showTransactionsPage }) => showTransactionsPage,
);

export const selectShowExecutiveSummary = createSelector(
  consumerArea,
  ({ showExecutiveSummary }) => showExecutiveSummary,
);

export const showFinancialPlanningSelector = createSelector(
  consumerArea,
  ({ showFinancialPlanning }) => showFinancialPlanning,
);

export const selectShowApexAcatsTransferFlow = createSelector(
  consumerArea,
  ({ showApexAcatsTransferFlow }) => showApexAcatsTransferFlow,
);

export const hasEStatementSettingsSelector = createSelector(
  consumerArea,
  ({ hasEStatementSettings }) => hasEStatementSettings,
);

export const showTwoFactorPromptSelector = createSelector(
  consumerArea,
  ({ showTwoFactorPrompt }) => showTwoFactorPrompt,
);

export const selectRiskToleranceQuestionnaire = createSelector(
  consumerArea,
  ({ riskToleranceQuestionnaire }) => riskToleranceQuestionnaire,
);

export const selectCanAccessPersonalFinances = createSelector(consumerArea, ({ client }) => {
  if (client && client.ModulesToHide) {
    return !client.ModulesToHide.includes('CurrentFinances');
  }

  return false;
});

export const hasTwoFactorAuthenticationSelector = createSelector(
  consumerArea,
  ({ hasTwoFactorAuthentication }) => hasTwoFactorAuthentication,
);

export const selectOrgLogo = createSelector(consumerArea, ({ orgLogo }) => orgLogo);

export const selectVersion = createSelector(consumerArea, ({ version }) => version);

export const selectPortalName = createSelector(consumerArea, ({ portalName }) => portalName);

export const selectPromptForTermsAndConditions = createSelector(
  consumerArea,
  ({ promptForTermsAndConditions }): boolean => promptForTermsAndConditions,
);

export const selectLanguagePreference = createSelector(
  consumerArea,
  ({ languagePreference }): LanguageCode | null => languagePreference,
);

export const selectFooterDisclosures = createSelector(
  consumerArea,
  ({ footerDisclosures }) => footerDisclosures,
);

export const selectHasBeFi20 = createSelector(consumerArea, ({ hasBeFi20 }): boolean => hasBeFi20);

export const selectHasPulseCheck = createSelector(
  consumerArea,
  ({ hasPulseCheck }): boolean => hasPulseCheck,
);

export const selectShowRelatedHouseholds = createSelector(
  consumerArea,
  ({ showRelatedHouseholds }): ShowRelatedHouseholdsOption => showRelatedHouseholds,
);

export const selectShowAlternativeDetail = createSelector(
  consumerArea,
  ({ showAlternativeDetail }): boolean => showAlternativeDetail,
);

export const showBrinkerMessageSelector = createSelector(
  consumerArea,
  ({ showBrinkerMessage }): boolean => showBrinkerMessage,
);

export const selectIsATnT = createSelector(consumerArea, ({ isATnT }): boolean => isATnT);

export const selectComponentName = createSelector(
  consumerArea,
  ({ componentName }): string | null => componentName,
);

export const selectComponentProps = createSelector(
  consumerArea,
  ({ componentProps }) => componentProps,
);

export const selectOrionWidgetsChartType = createSelector(
  consumerArea,
  ({ orionWidgetsChartType }) => orionWidgetsChartType,
);
