// Back-End enum contract for
// ConsumerAccessWorkflow.cs
export const CONSUMER_AREA_ACCESS_WORKFLOWS = {
  NONE: -1,
  COMPREHENSIVE: 0,
  MANAGED_ACCOUNTS: 1,
  RETIREMENT: 2,
  EDUCATION: 3,
  HOME_PURCHASE: 4,
  DEBT_MANAGEMENT: 5,
  ALL_WORKFLOWS: 6,
  LARGE_PURCHASE: 7,
  EMERGENCY_FUND: 8,
  ACCOUNTS_WORKFLOW: 9,
};

// Event keycodes: https://keycode.info/
export const EVENT_KEYCODES = {
  // eslint-disable-line import/prefer-default-export
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  DELETE: 46,
  ESCAPE: 27,
};

export const MESSAGES = {
  GENERIC_ERROR: 'There was an issue completing your request. Please try again later.',
};

export const PRIVACY_POLICY_URL = 'https://orion.com/download/privacy-policy';

export const STATES_US = [
  {
    name: 'Alabama',
    code2: 'AL',
    taxID: 0,
    isInsideUS: true,
  },
  {
    name: 'Alaska',
    code2: 'AK',
    taxID: 1,
    isInsideUS: true,
  },
  { name: 'American Samoa', code2: 'AS', isInsideUS: true },
  {
    name: 'Arizona',
    code2: 'AZ',
    taxID: 2,
    isInsideUS: true,
  },
  {
    name: 'Arkansas',
    code2: 'AR',
    taxID: 3,
    isInsideUS: true,
  },
  {
    name: 'California',
    code2: 'CA',
    taxID: 4,
    isInsideUS: true,
  },
  {
    name: 'Colorado',
    code2: 'CO',
    taxID: 5,
    isInsideUS: true,
  },
  {
    name: 'Connecticut',
    code2: 'CT',
    taxID: 6,
    isInsideUS: true,
  },
  {
    name: 'Delaware',
    code2: 'DE',
    taxID: 7,
    isInsideUS: true,
  },
  { name: 'District Of Columbia', code2: 'DC', isInsideUS: true },
  { name: 'Federated States Of Micronesia', code2: 'FM', isInsideUS: true },
  {
    name: 'Florida',
    code2: 'FL',
    taxID: 8,
    isInsideUS: true,
  },
  {
    name: 'Georgia',
    code2: 'GA',
    taxID: 9,
    isInsideUS: true,
  },
  { name: 'Guam', code2: 'GU', isInsideUS: true },
  {
    name: 'Hawaii',
    code2: 'HI',
    taxID: 10,
    isInsideUS: true,
  },
  {
    name: 'Idaho',
    code2: 'ID',
    taxID: 11,
    isInsideUS: true,
  },
  {
    name: 'Illinois',
    code2: 'IL',
    taxID: 12,
    isInsideUS: true,
  },
  {
    name: 'Indiana',
    code2: 'IN',
    taxID: 13,
    isInsideUS: true,
  },
  {
    name: 'Iowa',
    code2: 'IA',
    taxID: 14,
    isInsideUS: true,
  },
  {
    name: 'Kansas',
    code2: 'KS',
    taxID: 15,
    isInsideUS: true,
  },
  {
    name: 'Kentucky',
    code2: 'KY',
    taxID: 16,
    isInsideUS: true,
  },
  {
    name: 'Louisiana',
    code2: 'LA',
    taxID: 17,
    isInsideUS: true,
  },
  {
    name: 'Maine',
    code2: 'ME',
    taxID: 18,
    isInsideUS: true,
  },
  { name: 'Marshall Islands', code2: 'MH', isInsideUS: true },
  {
    name: 'Maryland',
    code2: 'MD',
    taxID: 19,
    isInsideUS: true,
  },
  {
    name: 'Massachusetts',
    code2: 'MA',
    taxID: 20,
    isInsideUS: true,
  },
  {
    name: 'Michigan',
    code2: 'MI',
    taxID: 21,
    isInsideUS: true,
  },
  {
    name: 'Minnesota',
    code2: 'MN',
    taxID: 22,
    isInsideUS: true,
  },
  {
    name: 'Mississippi',
    code2: 'MS',
    taxID: 23,
    isInsideUS: true,
  },
  {
    name: 'Missouri',
    code2: 'MO',
    taxID: 24,
    isInsideUS: true,
  },
  {
    name: 'Montana',
    code2: 'MT',
    taxID: 25,
    isInsideUS: true,
  },
  {
    name: 'Nebraska',
    code2: 'NE',
    taxID: 26,
    isInsideUS: true,
  },
  {
    name: 'Nevada',
    code2: 'NV',
    taxID: 27,
    isInsideUS: true,
  },
  {
    name: 'New Hampshire',
    code2: 'NH',
    taxID: 28,
    isInsideUS: true,
  },
  {
    name: 'New Jersey',
    code2: 'NJ',
    taxID: 29,
    isInsideUS: true,
  },
  {
    name: 'New Mexico',
    code2: 'NM',
    taxID: 30,
    isInsideUS: true,
  },
  {
    name: 'New York',
    code2: 'NY',
    taxID: 31,
    isInsideUS: true,
  },
  {
    name: 'North Carolina',
    code2: 'NC',
    taxID: 32,
    isInsideUS: true,
  },
  {
    name: 'North Dakota',
    code2: 'ND',
    taxID: 33,
    isInsideUS: true,
  },
  { name: 'Northern Mariana Islands', code2: 'MP', isInsideUS: true },
  {
    name: 'Ohio',
    code2: 'OH',
    taxID: 34,
    isInsideUS: true,
  },
  {
    name: 'Oklahoma',
    code2: 'OK',
    taxID: 35,
    isInsideUS: true,
  },
  {
    name: 'Oregon',
    code2: 'OR',
    taxID: 36,
    isInsideUS: true,
  },
  { name: 'Palau', code2: 'PW', isInsideUS: true },
  {
    name: 'Pennsylvania',
    code2: 'PA',
    taxID: 37,
    isInsideUS: true,
  },
  { name: 'Puerto Rico', code2: 'PR', isInsideUS: true },
  {
    name: 'Rhode Island',
    code2: 'RI',
    taxID: 38,
    isInsideUS: true,
  },
  {
    name: 'South Carolina',
    code2: 'SC',
    taxID: 39,
    isInsideUS: true,
  },
  {
    name: 'South Dakota',
    code2: 'SD',
    taxID: 40,
    isInsideUS: true,
  },
  {
    name: 'Tennessee',
    code2: 'TN',
    taxID: 41,
    isInsideUS: true,
  },
  {
    name: 'Texas',
    code2: 'TX',
    taxID: 42,
    isInsideUS: true,
  },
  {
    name: 'Utah',
    code2: 'UT',
    taxID: 43,
    isInsideUS: true,
  },
  {
    name: 'Vermont',
    code2: 'VT',
    taxID: 44,
    isInsideUS: true,
  },
  { name: 'Virgin Islands', code2: 'VI', isInsideUS: true },
  {
    name: 'Virginia',
    code2: 'VA',
    taxID: 45,
    isInsideUS: true,
  },
  {
    name: 'Washington',
    code2: 'WA',
    taxID: 46,
    isInsideUS: true,
  },
  {
    name: 'West Virginia',
    code2: 'WV',
    taxID: 47,
    isInsideUS: true,
  },
  {
    name: 'Wisconsin',
    code2: 'WI',
    taxID: 48,
    isInsideUS: true,
  },
  {
    name: 'Wyoming',
    code2: 'WY',
    taxID: 49,
    isInsideUS: true,
  },
  { name: 'Washington DC', taxID: 50, isInsideUS: true },
  { name: 'Other (US Person)', taxID: 51, isInsideUS: false },

  { name: 'Bermuda', taxID: 1000, isInsideUS: false },
  { name: 'Cayman Islands', taxID: 1001, isInsideUS: false },

  // Canada
  { name: 'Alberta', taxID: 2000, isInsideUS: false, isCanada: true },
  { name: 'British Columbia', taxID: 2001, isInsideUS: false, isCanada: true },
  { name: 'Manitoba', taxID: 2002, isInsideUS: false, isCanada: true },
  { name: 'New Brunswick', taxID: 2003, isInsideUS: false, isCanada: true },
  { name: 'Newfoundland and Labrador', taxID: 2004, isInsideUS: false, isCanada: true },
  { name: 'Northwest Territories', taxID: 2005, isInsideUS: false, isCanada: true },
  { name: 'Nova Scotia', taxID: 2006, isInsideUS: false, isCanada: true },
  { name: 'Nunavut', taxID: 2007, isInsideUS: false, isCanada: true },
  { name: 'Ontario', taxID: 2008, isInsideUS: false, isCanada: true },
  { name: 'Prince Edward Island', taxID: 2009, isInsideUS: false, isCanada: true },
  { name: 'Quebec', taxID: 2010, isInsideUS: false, isCanada: true },
  { name: 'Saskatchewan', taxID: 2011, isInsideUS: false, isCanada: true },
  { name: 'Yukon', taxID: 2012, isInsideUS: false, isCanada: true },
];

export const ABBREVIATED_STATES_US = [
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'FM',
    value: 'FM',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MH',
    value: 'MH',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PW',
    value: 'PW',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WY',
    value: 'WY',
  },
];
