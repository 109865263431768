import { object, string } from 'yup';

export enum LoginFormStep {
  Email,
  Password,
}

export interface FormValues {
  username: string;
  password: string;
}

export const validation = object({
  username: string().required('Please specify your email or username'),
  password: string().when('$step', {
    is: LoginFormStep.Password,
    then: schema => schema.required('Please specify your password'),
  }),
});

export interface TwoFactorAuthMethod {
  id: string;
  type: 'Email' | 'Phone';
  mask: string;
}

interface LoginResponseViewData {
  redirectUrl?: string;
  loginSessionId?: string;
  twoFactorAuthMethods?: TwoFactorAuthMethod[];
  mfaEnrollment?: boolean;
  captchaRequired?: boolean;
  message?: string;
}

export type LoginResponse =
  | {
      success: true;
      errors: null;
      payload: { viewData: LoginResponseViewData };
    }
  | {
      success: false;
      errors: string[];
      payload: null;
    };

export type LoginFn = (data: { username?: string; password?: string; mfaCode?: string }) => void;

export type SendCodeFn = (data: { methodId?: string }) => void;

export const GENERIC_ERROR = 'There was an issue completing your request. Please try again later.';
