import styled from '../../../theme/theme';

export const Wrapper = styled.div(({ theme: { space } }) => ({
  minHeight: '100vh',
  width: '100%',
  background: 'linear-gradient(180deg, #F4F5F7 0%, #FFFFFF 100%);',
  padding: space[32],
  paddingTop: 88,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
}));

export const Footer = styled.footer(() => ({ marginTop: 'auto' }));

export const FooterLinks = styled.ul(({ theme: { colors, space } }) => ({
  listStyle: 'none',
  padding: 0,
  color: colors.primary[500],
  li: {
    paddingLeft: space[16],
    paddingRight: space[16],
    display: 'inline-block',
  },
  'li + li': {
    borderLeft: `1px solid ${colors.neutral[400]}`,
  },
}));
