import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { color, DisplayProps, layout, space, typography, variant } from 'styled-system';
import { ColorProps, LayoutProps, SpaceProps, TypographyProps } from '../../../theme/styledProps';

export const textVariantList = ['smallCaps', 'cardTitle', 'tabular', 'formLabel'] as const;
type TextVariant = typeof textVariantList[number];

export interface TextProps
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    LayoutProps,
    ComponentPropsWithoutRef<'div'> {
  textAlign?: 'left' | 'right' | 'center' | 'justify';
  /**
   * Use this prop to truncate the text with an ellipsis
   */
  isTruncated?: boolean;
  variant?: TextVariant;
  isOverflowWrap?: boolean;
}

export const truncatedStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const overflowWrapStyle: React.CSSProperties = {
  wordBreak: 'break-word', // "overflow-wrap: anywhere" is not supported in Safari
  overflowWrap: 'anywhere',
};

export const Text = styled.div<TextProps>(
  // CSS reset
  {
    margin: 0,
  },

  space,
  typography,
  color,
  layout,

  ({ theme, textTransform, textAlign, fontSize, isOverflowWrap, isTruncated }) => ({
    fontFamily: theme.fontFamily,
    lineHeight: theme.lineHeights[fontSize!],
    boxSizing: 'border-box',
    textTransform,
    textAlign,
    ...(isOverflowWrap ? overflowWrapStyle : {}),
    ...(isTruncated ? truncatedStyle : {}),

    '& strong': {
      color: 'inherit',
      fontWeight: 600,
    },
  }),

  variant<TypographyProps & ColorProps & DisplayProps, TextVariant>({
    variants: {
      smallCaps: {
        fontWeight: 600,
        fontSize: 'small',
        lineHeight: 'small',
        color: 'neutral.500',
        letterSpacing: 'wide',
        textTransform: 'uppercase',
      },

      cardTitle: {
        fontWeight: 600,
        fontSize: 'h6',
        color: 'neutral.700',
      },

      tabular: {
        fontVariantNumeric: 'tabular-nums',
      },

      formLabel: {
        fontSize: 'small',
        lineHeight: 'small',
        fontWeight: 600,
        display: 'inline-block',
      },
    },
  }),
);

Text.defaultProps = {
  fontWeight: 400,
  fontSize: 'body',
  color: 'neutral.700',
  textTransform: 'none',
};

Text.displayName = 'Text';
