import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import {
  FormValues,
  GENERIC_ERROR,
  LoginFn,
  LoginFormStep,
  LoginResponse,
  SendCodeFn,
  TwoFactorAuthMethod,
  validation,
} from './Login.meta';
import { ApiError } from '../../errors/ApiError';
import { useIsMobile } from '../../_hooks/useIsMobile';

declare const VIEW_DATA: {
  model: {
    UserName: string | null;
    Password: string | null;
    ErrorMessage: string | null;
    ReturnUrlRoute: string | null;
    BrandingLogoFileName: string;
    SelfRegistrationReferrerUrlId: string | null;
    OrganizationId: string | null;
    AdviserId: string | null;
  };
  csrfToken: string;
  assetsEndpoint: string;
  termsAndConditionsUrl: string;
};

export const useLogin = () => {
  const loginSessionIdRef = useRef<string | null>(null);

  const [mfaMethods, setMfaMethods] = useState<TwoFactorAuthMethod[] | null>(null);

  useEffectOnce(() => {
    // prevent swipe back to the app after logout
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('action') === 'logout') {
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.history.go(1);
      };
    }
  });

  const [step, setStep] = useState(
    VIEW_DATA.model.UserName ? LoginFormStep.Password : LoginFormStep.Email,
  );

  const [backendError, setBackendError] = useState<string | null>(null);

  const [redirectData, setRedirectData] = useState<{ message: string; url: string } | null>(null);

  const { register, formState, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      username: '',
      password: '',
    },
    context: {
      step,
    },
    resolver: yupResolver(validation),
  });

  const email = watch('username');

  const isMobile = useIsMobile();

  const login: LoginFn = async data => {
    const formData = new FormData();

    if (data.username) {
      formData.append('UserName', data.username);
    }

    if (data.password) {
      formData.append('Password', data.password);
    }

    if (data.mfaCode) {
      formData.append('mfaCode', data.mfaCode);
    }

    if (loginSessionIdRef.current) {
      formData.append('loginSessionId', loginSessionIdRef.current);
    }

    if (VIEW_DATA.model.ReturnUrlRoute) {
      formData.append('returnurl', VIEW_DATA.model.ReturnUrlRoute);
    }

    if (VIEW_DATA.model.SelfRegistrationReferrerUrlId) {
      formData.append('referrerUrlId', VIEW_DATA.model.SelfRegistrationReferrerUrlId);
    }

    formData.append('__RequestVerificationToken', VIEW_DATA.csrfToken);

    const response = await fetch('/Session/Index', {
      method: 'POST',
      body: formData,
      headers: { accept: 'application/json' },
    });

    if (response.ok) {
      const { success, errors, payload } = (await response.json()) as LoginResponse;

      if (!success) {
        throw new ApiError(errors[0]);
      }

      const {
        redirectUrl,
        loginSessionId,
        twoFactorAuthMethods,
        mfaEnrollment,
        captchaRequired,
        message,
      } = payload.viewData;

      if ((mfaEnrollment || captchaRequired) && redirectUrl && message) {
        setRedirectData({
          message,
          url: redirectUrl,
        });

        return;
      }

      if (redirectUrl) {
        const parsedUrl = new URL(redirectUrl, window.location.origin);

        parsedUrl.searchParams.set('isMobile', isMobile.toString());

        window.location.href = parsedUrl.toString();

        return;
      }

      if (loginSessionId && twoFactorAuthMethods) {
        loginSessionIdRef.current = loginSessionId;

        setMfaMethods(twoFactorAuthMethods);
      }
    } else {
      throw new Error(GENERIC_ERROR);
    }
  };

  const sendCode: SendCodeFn = async data => {
    const formData = new FormData();

    if (data.methodId) {
      formData.append('twoFactorAuthMethodId', data.methodId);
    }

    if (loginSessionIdRef.current) {
      formData.append('loginSessionId', loginSessionIdRef.current);
    }

    formData.append('__RequestVerificationToken', VIEW_DATA.csrfToken);

    const response = await fetch('/Session/SendMfaCodeToUser', {
      method: 'POST',
      body: formData,
      headers: { accept: 'application/json' },
    });

    if (response.ok) {
      const { success, errors } = await response.json();

      if (!success) {
        throw new ApiError(errors[0]);
      }
    } else {
      throw new Error(GENERIC_ERROR);
    }
  };

  const onSubmit = handleSubmit(async data => {
    if (step === LoginFormStep.Email) {
      setStep(LoginFormStep.Password);
    } else {
      try {
        await login(data);
      } catch (err) {
        if (err instanceof ApiError) {
          setBackendError(err.message);
        } else {
          setBackendError(GENERIC_ERROR);
        }
      }
    }
  });

  return {
    onSubmit,
    email,
    register,
    formState,
    backendError,
    step,
    setStep,
    mfaMethods,
    login,
    sendCode,
    onBack: () => {
      setStep(LoginFormStep.Email);
      setBackendError(null);
    },
    logoUrl: VIEW_DATA.model.BrandingLogoFileName,
    assetsEndpoint: VIEW_DATA.assetsEndpoint,
    termsAndConditionsUrl: VIEW_DATA.termsAndConditionsUrl,
    redirectData,
  };
};
