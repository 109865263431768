import styled from 'styled-components';
import { variant } from 'styled-system';
import { Button, Props as ButtonProps } from '../../../Button/Button';
import { AlertVariant } from '../../Alert.styles';
import { ColorProps, PropsWithPseudo } from '../../../../../theme/styledProps';
import { Pseudo } from '../../../Button/Button.styles';

interface Props extends ButtonProps {
  alertVariant: AlertVariant;
}

export const ExtraActionButton = styled(Button).attrs({ variant: 'link' })<Props>(
  { height: 20 },
  variant<PropsWithPseudo<ColorProps, Pseudo>, AlertVariant>({
    prop: 'alertVariant',
    variants: {
      neutral: {
        color: 'neutral.700',

        '&:not(.disabled):hover': {
          color: 'neutral.600',
          bg: 'transparent',
        },

        '&:not(.disabled):active': {
          color: 'neutral.600',
          bg: 'transparent',
        },
      },

      info: {
        color: 'info.700',

        '&:not(.disabled):hover': {
          color: 'info.600',
          bg: 'transparent',
        },

        '&:not(.disabled):active': {
          color: 'info.600',
          bg: 'transparent',
        },
      },

      success: {
        color: 'success.700',

        '&:not(.disabled):hover': {
          color: 'success.600',
          bg: 'transparent',
        },

        '&:not(.disabled):active': {
          color: 'success.600',
          bg: 'transparent',
        },
      },

      warning: {
        color: 'neutral.700',

        '&:not(.disabled):hover': {
          color: 'neutral.600',
          bg: 'transparent',
        },

        '&:not(.disabled):active': {
          color: 'neutral.600',
          bg: 'transparent',
        },
      },

      error: {
        color: 'error.700',

        '&:not(.disabled):hover': {
          color: 'error.600',
          bg: 'transparent',
        },

        '&:not(.disabled):active': {
          color: 'error.600',
          bg: 'transparent',
        },
      },
    },
  }),
);
