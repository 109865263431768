import {
  ChartType,
  OnboardingWorkflow,
  RiskToleranceQuestionnaireType,
} from '../CommonSelectors/CommonArea.types';

export enum DashboardFeatureId {
  CurrentFinances = 1,
  FinancialPlan,
  WhatIfs,
  DocumentVault,
  ContactAdviser,
  EditProfile,
  Performance,
  Transactions,
  Newsfeed,
  NetWorth,
}

type DashboardAccessWorkflowPreset = {
  DashboardAccess: DashboardFeatureId;
  IsReadOnly: boolean;
}[];

export type DashboardAccessWorkflowPresets = Record<
  OnboardingWorkflow,
  DashboardAccessWorkflowPreset
>;

export interface CurrentClientDTO {
  Id: number;
  IsOrionClient: boolean;
  CanPreviewClient: boolean;
  IsRegistered: boolean;
  Email: string | null;
  HasMoneyGuideProAccess: boolean;
  HasEMoneyAccess: boolean;
  HasSchwabAccess: boolean;
  HasRISAAccess: boolean;
  HasOrionTradingAccess: boolean;
  HasDataReconciliationAccess: boolean;
  HasReportsAccess: boolean;
  ShowApexAcatsTransferFlow: boolean;
  HasEStatementSettings: boolean;
  HasRightCapitalAccess: boolean;
  ClientAccess: {
    RiskToleranceQuestionnaire: RiskToleranceQuestionnaireType;
  };
  IsCanadian: boolean;
  HasBeFi20: boolean;
  HasPulseCheck: boolean;
  ShowAlternativeDetail: boolean;
  OrionWidgetsChartType: ChartType;
  ShowIncomeTab: boolean;
}

export interface Adviser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}
