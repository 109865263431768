import React from 'react';
import { components } from 'react-select';
import { Icon } from '../../../../Icon/Icon';

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon className="fas fa-caret-down" color="neutral.400" fontSize="small" />
    </components.DropdownIndicator>
  );
};
