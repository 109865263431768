import styled from 'styled-components';
import { variant } from 'styled-system';
import { ColorProps, PropsWithPseudo, TypographyProps } from '../../../theme/styledProps';

export type ModalHeaderVariant = 'default' | 'success' | 'warning' | 'error';

export type Pseudo = '&::before';

export interface Props {
  variant?: ModalHeaderVariant;
  hasBorder?: boolean;
}

export const ModalHeader = styled.div<Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 64px 16px 24px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral['700']};
  border-bottom: ${({ hasBorder, theme }) =>
    hasBorder ? `1px solid ${theme.colors.neutral['200']}` : undefined};
  margin: 0;

  &::before {
    font-family: 'Font Awesome 5 Pro';
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin-right: 8px;
    font-size: 16px;
    flex-shrink: 0;
  }

  ${variant<
    PropsWithPseudo<ColorProps & TypographyProps & { content?: string }, Pseudo>,
    ModalHeaderVariant
  >({
    variants: {
      default: {
        '&::before': {
          content: 'none',
        },
      },

      success: {
        '&::before': {
          content: "'\f058'",
          color: 'success.600',
          bg: 'success.200',
          fontWeight: 600,
        },
      },

      warning: {
        '&::before': {
          content: "'\f071'",
          color: 'warning.600',
          bg: 'warning.100',
          fontWeight: 600,
        },
      },

      error: {
        '&::before': {
          content: "'\f2f0'",
          color: 'error.600',
          bg: 'error.100',
          fontWeight: 600,
        },
      },
    },
  })}
`;

ModalHeader.defaultProps = {
  variant: 'default',
};
