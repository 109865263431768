import { shadow } from 'styled-system';
import styled from '../../../theme/theme';
import { Box, BoxProps } from '../Box/Box';

export interface CardProps extends BoxProps {
  hasBorder?: boolean;
}

export const Card = styled(Box)<CardProps>(
  ({ theme, hasBorder }) => ({
    background: theme.colors.bg,
    outline: 'none',
    border: hasBorder ? `1px solid ${theme.colors.neutral['200']}` : 'none',

    '@media print': {
      boxShadow: 'none',
      border: `1px solid ${theme.colors.neutral['200']}`,
    },
  }),

  shadow,
);

Card.defaultProps = {
  boxShadow: '400',
  borderRadius: 'medium',
};
