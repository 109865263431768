import { ToastContainer as RTToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { ColorProps, PropsWithPseudo, TypographyProps } from '../../../theme/styledProps';
import { MAX_Z_INDEX } from '../../../theme/theme';

export const ToastWrapper = styled.div(({ theme }) => ({
  zIndex: 2,
  background: theme.colors.neutral['700'],
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  color: theme.colors.white,
  position: 'relative',
}));

export type ToastVariant = 'default' | 'info' | 'success' | 'warning' | 'error' | 'progress';

export type ToastTitlePseudo = '&::before';

interface ToastTitleProps {
  variant: ToastVariant;
}

export const ToastTitle = styled.div<ToastTitleProps>(
  ({ theme }) => ({
    fontFamily: theme.fontFamily,
    fontWeight: 400,
    fontSize: '14px',
    color: theme.colors.white,
    paddingLeft: '24px',

    '&::before': {
      fontFamily: '"Font Awesome 5 Pro"',
      width: 16,
      height: 16,
      marginRight: 8,
      fontSize: theme.fontSizes.h6,
      flexShrink: 0,
      position: 'absolute',
      top: 1,
      left: 0,
    },
  }),

  variant<
    PropsWithPseudo<ColorProps & TypographyProps & { content?: string }, ToastTitlePseudo>,
    ToastVariant
  >({
    variants: {
      default: {
        '&::before': {
          content: "'\f05a'",
          color: 'bg',
          fontWeight: 400,
        },
      },

      info: {
        '&::before': {
          content: "'\f05a'",
          color: 'info.400',
          fontWeight: 400,
        },
      },

      success: {
        '&::before': {
          content: "'\f058'",
          color: 'success.400',
          fontWeight: 600,
        },
      },

      warning: {
        '&::before': {
          content: "'\f071'",
          color: 'warning.300',
          fontWeight: 600,
        },
      },

      error: {
        '&::before': {
          content: "'\f2f0'",
          color: 'error.300',
          fontWeight: 600,
        },
      },

      progress: {
        '&::before': {
          content: "'\f141'",
          color: 'white',
        },
      },
    },
  }),
);

export const StyledToastContainer = styled(RTToastContainer)`
  z-index: ${MAX_Z_INDEX};

  .Toastify__toast-container {
    color: ${({ theme }) => theme.colors.white};
  }

  .Toastify__toast-body {
    margin: auto 0;
    padding: 6px;
    width: 100%;
  }

  .Toastify__toast--default,
  .Toastify__toast--error,
  .Toastify__toast--warning,
  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.neutral['700']};
    border: ${({ theme }) => `1px solid ${theme.colors.neutral['800']}`};
    color: ${({ theme }) => theme.colors.white};
  }

  .Toastify__toast-body {
    top: 2px;
    left: 0;
  }

  .Toastify__close-button,
  .Toastify__close-button--default {
    color: ${({ theme }) => theme.colors.neutral['300']};
    line-height: 16px;
    height: 16px;

    &:not(.disabled):hover,
    &:not(.disabled):active,
    &:not(.disabled):focus {
      text-shadow: none;
      background: none;
    }
  }

  .Toastify__progress-bar,
  .Toastify__progress-bar--default {
    background: white;
    height: 4px;
  }

  @keyframes slide-top {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-top {
    animation: slide-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  }

  @keyframes slide-bottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(10px);
      opacity: 0;
    }
  }

  .slide-bottom {
    animation: slide-bottom 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  }
`;
