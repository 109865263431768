import styled from 'styled-components';
import { variant } from 'styled-system';
import { Button, Props as ButtonProps } from '../../../Button/Button';
import { AlertVariant } from '../../Alert.styles';
import { ColorProps, PropsWithPseudo } from '../../../../../theme/styledProps';
import { Pseudo } from '../../../Button/Button.styles';

interface Props extends ButtonProps {
  alertVariant: AlertVariant;
}

export const ActionButton = styled(Button).attrs({ variant: 'subtleInactive' })<Props>(
  variant<PropsWithPseudo<ColorProps, Pseudo>, AlertVariant>({
    prop: 'alertVariant',
    variants: {
      neutral: {
        color: 'neutral.600',

        '&:not(.disabled):hover': {
          bg: 'neutral.200',
        },

        '&:not(.disabled):active': {
          bg: 'neutral.300',
        },
      },

      info: {
        color: 'info.700',

        '&:not(.disabled):hover': {
          bg: 'info.100',
        },

        '&:not(.disabled):active': {
          bg: 'info.200',
        },
      },

      success: {
        color: 'success.700',

        '&:not(.disabled):hover': {
          bg: 'success.100',
        },

        '&:not(.disabled):active': {
          bg: 'success.200',
        },
      },

      warning: {
        color: 'neutral.600',

        '&:not(.disabled):hover': {
          bg: 'neutral.200',
        },

        '&:not(.disabled):active': {
          bg: 'neutral.300',
        },
      },

      error: {
        color: 'error.600',

        '&:not(.disabled):hover': {
          bg: 'error.100',
        },

        '&:not(.disabled):active': {
          bg: 'error.200',
        },
      },
    },
  }),
);
