import styled from 'styled-components';
import { BoxShadowProps, PositionProps, typography, variant } from 'styled-system';
import { Wrapper as WrapperBase } from '../Wrapper/Wrapper';
import { Text } from '../Text/Text';
import {
  BorderProps,
  LayoutProps,
  PaddingProps,
  PropsWithPseudo,
  TypographyProps,
} from '../../../theme/styledProps';

// Hide spin box for Input type number
export const Wrapper = styled(WrapperBase)`
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export type FormVariant = 'success' | 'warning' | 'error';
export type InputSizeVariant = 'small' | 'medium' | 'large';

interface StyledInputProps {
  formVariant?: FormVariant;
  sizeVariant: InputSizeVariant;
  leftIcon?: string;
  rightIcon?: string;
}

export const StyledInput = styled.input<StyledInputProps>(
  typography,
  ({ theme }) => ({
    fontFamily: '"Inter", sans-serif',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    outline: 'none',
    appearance: 'none',
    padding: '0 8px',
    borderRadius: theme.radii.medium,
    borderWidth: '1px',
    borderColor: theme.colors.neutral['300'],
    borderStyle: 'solid',
    backgroundColor: theme.colors.bg,
    color: theme.colors.neutral['700'],
    boxShadow: '0px 1px 2px rgba(55, 65, 81, 0.08)',
    boxSizing: 'border-box',
    transition: `border-color ${theme.transitions.normal}, box-shadow ${theme.transitions.normal}`,

    '&:hover': {
      borderColor: theme.colors.neutral['400'],
      boxShadow: '0px 1px 2px rgba(55, 65, 81, 0.06), 0px 1px 3px rgba(55, 65, 81, 0.1)',
    },

    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.colors.primary['300']}`,
      borderColor: theme.colors.primary['500'],
    },

    '&:disabled': {
      color: theme.colors.neutral['500'],
      background: theme.colors.neutral['100'],
      borderColor: theme.colors.neutral['300'],
      pointerEvents: 'none',
      boxShadow: 'none',
    },

    '&:read-only': {
      color: theme.colors.neutral['500'],
      background: theme.colors.neutral['100'],
      borderColor: theme.colors.neutral['300'],
      boxShadow: 'none',
    },

    '&::placeholder': {
      color: theme.colors.neutral['400'],
    },
  }),

  ({ theme }) =>
    variant<PropsWithPseudo<BorderProps & BoxShadowProps, '&:focus' | '&:hover'>, FormVariant>({
      prop: 'formVariant',
      variants: {
        success: {
          borderColor: 'success.600',

          '&:hover': {
            borderColor: 'success.600',
          },

          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.success['300']}`,
            borderColor: 'success.600',
          },
        },

        warning: {},

        error: {
          borderColor: 'error.500',

          '&:hover': {
            borderColor: 'error.500',
          },

          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.error['200']}`,
            borderColor: 'error.500',
          },
        },
      },
    }),

  ({ leftIcon, rightIcon }) =>
    variant<TypographyProps & LayoutProps & PaddingProps, InputSizeVariant>({
      prop: 'sizeVariant',
      variants: {
        small: {
          fontSize: 'small',
          lineHeight: 'small',
          height: 32,
          pl: leftIcon ? 26 : 8,
          pr: rightIcon ? 26 : 8,
        },
        medium: {
          fontSize: 'body',
          lineHeight: 'body',
          height: 36,
          pl: leftIcon ? 30 : 8,
          pr: rightIcon ? 30 : 8,
        },
        large: {
          fontSize: 'body',
          lineHeight: 'body',
          height: 40,
          pl: leftIcon ? 32 : 8,
          pr: rightIcon ? 32 : 8,
        },
      },
    }),
);

interface InputIconProps {
  isLeft?: boolean;
  isRight?: boolean;
  size?: InputSizeVariant;
  disabled?: boolean;
}

export const InputIcon = styled.i<InputIconProps>(
  ({ isLeft, isRight, disabled, theme }) => ({
    margin: 0,
    padding: '0 1px',
    marginRight: isLeft ? 8 : 0,
    marginLeft: isRight ? 8 : 0,
    left: isLeft ? 10 : undefined,
    right: isRight ? 10 : undefined,
    color: disabled ? theme.colors.neutral['400'] : theme.colors.neutral['500'],
    position: 'absolute',
    zIndex: 2,
    pointerEvents: 'none',
  }),

  variant<TypographyProps & PositionProps, InputSizeVariant>({
    prop: 'size',
    variants: {
      small: {
        fontSize: 'small',
        top: 11,
      },

      medium: {
        fontSize: 'body',
        top: 11,
      },

      large: {
        fontSize: 'h6',
        top: 12,
      },
    },
  }),
);

interface InnerTextProps {
  size: InputSizeVariant;
}

export const InnerText = styled(Text)<InnerTextProps>(
  ({ theme }) => ({
    color: theme.colors.neutral['500'],
    fontWeight: 500,
    pointerEvents: 'none',
    position: 'absolute',
  }),

  variant<TypographyProps & PositionProps, InputSizeVariant>({
    prop: 'size',
    variants: {
      small: {
        fontSize: 'small',
        top: 6,
        right: 8,
      },

      medium: {
        fontSize: 'body',
        top: 8,
        right: 8,
      },

      large: {
        fontSize: 'body',
        top: 10,
        right: 10,
      },
    },
  }),
);
