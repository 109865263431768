export const palette = {
  white: '#fff',
  black: '#18191b',

  gray: {
    50: '#f9fafb',
    100: '#f4f5f7',
    200: '#e5e7eb',
    300: '#d1d4dc',
    400: '#9fa5b2',
    500: '#6c727f',
    600: '#494f5a',
    700: '#383c42',
    800: '#26282b',
    900: '#1d1e20',
  },

  blue: {
    50: '#f3fbff',
    100: '#e6f5ff',
    200: '#cce7ff',
    300: '#7ec4f6',
    400: '#009ff9',
    500: '#0078d4',
    600: '#1960a6',
    700: '#104c8b',
    800: '#093970',
    900: '#052452',
  },

  indigo: {
    50: '#f0f5ff',
    100: '#e5edff',
    200: '#cddbfe',
    300: '#b4c6fc',
    400: '#8da2fb',
    500: '#6875f5',
    600: '#5850ec',
    700: '#5145cd',
    800: '#42389d',
    900: '#362f78',
  },

  purple: {
    50: '#f0f5ff',
    100: '#edebfe',
    200: '#dcd7fe',
    300: '#cabffd',
    400: '#ac94fa',
    500: '#9061f9',
    600: '#7e3af2',
    700: '#6c2bd9',
    800: '#5521b5',
    900: '#4a1d96',
  },

  pink: {
    50: '#fdf2f8',
    100: '#fce8f3',
    200: '#fad1e8',
    300: '#f8b4d9',
    400: '#f17eb8',
    500: '#e74694',
    600: '#d61f69',
    700: '#bf125d',
    800: '#99154b',
    900: '#751a3d',
  },

  red: {
    50: '#ffeded',
    100: '#ffced0',
    200: '#feaeb6',
    300: '#f97386',
    400: '#ef4268',
    500: '#e01e5a',
    600: '#c7094d',
    700: '#a60048',
    800: '#7e0042',
    900: '#540032',
  },

  orange: {
    50: '#fff8f1',
    100: '#feecdc',
    200: '#fcd9bd',
    300: '#fdba8c',
    400: '#ff9457',
    500: '#eb6a37',
    600: '#d03801',
    700: '#b43403',
    800: '#8a2c0d',
    900: '#771d1d',
  },

  yellow: {
    50: '#fdfdea',
    100: '#fdf6b2',
    200: '#fce96a',
    300: '#faca15',
    400: '#e3a008',
    500: '#c27803',
    600: '#9f580a',
    700: '#8e4b10',
    800: '#723b13',
    900: '#633112',
  },

  green: {
    50: '#f3faf7',
    100: '#def7ec',
    200: '#bcf0da',
    300: '#84e1bc',
    400: '#31c48d',
    500: '#0e9f6e',
    600: '#007a5a',
    700: '#036d55',
    800: '#0a5c4d',
    900: '#014737',
  },

  teal: {
    50: '#edfafa',
    100: '#d5f5f6',
    200: '#afecef',
    300: '#7edce2',
    400: '#16bdca',
    500: '#0694a2',
    600: '#047481',
    700: '#036672',
    800: '#05505c',
    900: '#014451',
  },

  coolgrey: {
    50: '#fbfdfe',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cfd8e3',
    400: '#97a6ba',
    500: '#64748b',
    600: '#475569',
    700: '#364152',
    800: '#27303f',
    900: '#1a202e',
  },
};
