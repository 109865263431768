import styled from 'styled-components';
import { variant } from 'styled-system';
import { LayoutProps, PaddingProps } from '../../../theme/styledProps';
import { ButtonSize, ButtonVariant } from '../Button/Button.styles';
import { Button } from '../Button/Button';

export interface StyledIconButtonProps {
  variant: ButtonVariant;
  size: ButtonSize;
  showCaret?: boolean;
}

export const StyledIconButton = styled(Button)<StyledIconButtonProps>(
  ({ theme, showCaret }) => ({
    '&::after': {
      content: showCaret ? '"\f0d7"' : '',
      fontSize: theme.fontSizes.small,
      fontFamily: '"Font Awesome 5 Pro"',
      fontWeight: 700,
      color: 'inherit',
      marginLeft: '4px',
    },
  }),

  variant<LayoutProps & PaddingProps, ButtonSize>({
    prop: 'size',
    variants: {
      small: {
        height: 24,
        width: 24,
        p: 0,
      },
      medium: {
        height: 36,
        width: 36,
        p: 0,
      },
      large: {
        height: 48,
        width: 48,
        p: 0,
      },
    },
  }),
);
